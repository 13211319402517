<template>
  <el-container style="display: flex; flex-direction: column; height: 100%;">
    <div class="full-screen-loading" v-show="showFullScreenLoading">
      <p style="color: white;">File loading, please wait for a moment...&nbsp;&nbsp;&nbsp;</p>
      <div class="loading-spinner"></div>
    </div>
    <el-header>
      <Header></Header>
    </el-header>
    <el-main class="niam">
      <div style="justify-content: center;display: flex;border-bottom: black 1px solid;margin-top: -21px;">
        <!--        <img src="@/assets/11.png" width="166" height="166">-->
        <div style="font-size: 52px;font-family: Arial;color: #6a4760"><p>SARS-Cov2</p></div>
      </div>
      <div style="justify-content: center;display: flex;border-bottom: black 1px solid;height: 166px;">
        <p style="font-size: 28px">说明</p>
      </div>
      <div style="justify-content: center;display: flex;border-bottom: none;height: 299px;">
        <table>
          <thead>
          <tr>
            <th>ID</th>
            <th>PID</th>
            <th>Description</th>
            <th>Author</th>
            <th>Release Date</th>
            <th style="border-left: white 3px solid;">More</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(cov,index) in covList" :key="index">
            <td>{{ index }}</td>
            <td class="tdLink">{{ cov.pid }}</td>
            <td class="tdLink">{{ cov.description }}</td>
            <td>{{ cov.author }}</td>
            <td>{{ cov.releaseDate }}</td>
            <td style="border-left: white 4px solid;">
              <button @click="getCovPdbDateByPid(cov.pid)">more</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <el-dialog
          center
          v-model="dialogVisible"
          width="79%"
          style="border-radius: 3px;"
      >
        <div style="display: flex;height: 600px;">
          <div id="container-01" class="mol-container" ref="molContainer">
          </div>
          <div class="description-list">
            <div class="description-item">
              <div class="description-label">Pid:</div>
              <div class="description-value">{{ this.covPdb.pid }}</div>
            </div>
            <div class="description-item">
              <div class="description-label">Description:</div>
              <div class="description-value">{{ this.covPdb.description }}</div>
            </div>
            <div class="description-item">
              <div class="description-label">Total Structure Weight:</div>
              <div class="description-value">{{ this.covPdb.totalStructureWeight }}</div>
            </div>
            <div class="description-item">
              <div class="description-label">Atom Count:</div>
              <div class="description-value">{{ this.covPdb.atomCount }}</div>
            </div>
            <div class="description-item">
              <div class="description-label">Modelled Residue Count:</div>
              <div class="description-value">{{ this.covPdb.modelledResidueCount }}</div>
            </div>
            <div class="description-item">
              <div class="description-label">Deposited Residue Count:</div>
              <div class="description-value">{{ this.covPdb.depositedResidueCount }}</div>
            </div>
            <div class="description-item">
              <div class="description-label">Unique Protein Chain:</div>
              <div class="description-value">{{ this.covPdb.uniqueProteinChain }}</div>
            </div>
            <div class="description-item">
              <div class="description-label">Release Date:</div>
              <div class="description-value">{{ this.covPdb.releaseDate }}</div>
            </div>
            <div class="description-item">
              <div class="description-label">Author:</div>
              <div class="description-value">{{ this.covPdb.author }}</div>
            </div>
          </div>
        </div>

        <template #title>
          <button style="margin-left: 80%;cursor: pointer;" @click="saveAsPDBFile">Download Pbd File</button>
        </template>
      </el-dialog>
    </el-main>
  </el-container>
</template>

<script>
import Header from "@/views/Index/Header.vue";
import {getRequest} from "@/utils/api";
import * as $3Dmol from "3dmol";
import {ElMessage} from "element-plus";

export default {
  name: "CovSearch",
  components: {Header},
  data() {
    return {
      covList: [],
      covListAll: [],
      getAllTrue: false,
      viewerList: [],
      covPdb: {},
      cov: {},
      dialogVisible: false,
      viewer: null,
      showFullScreenLoading: false
    }
  },
  created() {
    //getData
    this.getCovlist();
  },
  mounted() {
    //createViewer
  },
  methods: {
    saveAsPDBFile() {
      const blob = new Blob([this.covPdb.pdbStr], {type: 'chemical/x-pdb'});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = this.covPdb.pid + '.pdb';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getCovlist() {
      getRequest('/cov/list').then(resp => {
        if (resp) {
          this.covList = resp.obj;
        }
      })
    },
    getCovPdbDateByPid(pid) {
      this.dialogVisible = true;
      this.showFullScreenLoading = true;
      if (pid == this.covPdb.pid) {
        this.showFullScreenLoading = false;
        return;
      } else
        this.covPdb = {};
      getRequest('/cov/' + pid).then(resp => {
        if (resp) {
          this.cov = resp.obj;
          this.covPdb.pdbStr = this.cov.pdbStr;
          this.setView();
          this.covPdb = this.cov;
          this.showFullScreenLoading = false;
        }
      })
    },
    setView() {
      this.viewer = $3Dmol.createViewer(this.$refs.molContainer, {});
      this.viewer.addModel(this.covPdb.pdbStr, 'pdb');
      this.viewer.setStyle({}, {cartoon: {colorscheme: 'red'}, sphere: {radius: 0.2}});
      this.viewer.render();
      this.viewer.zoomTo();
      this.viewer.render();
      this.viewer.zoom(1.2, 1000);
    },
  }
}
</script>

<style scoped>

.full-screen-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mol-container {
  width: 60%;
  /*height: 311px;*/
  position: relative;
  margin-left: 2%;
  box-shadow: 0 1px 6px rgba(134, 129, 129, 0.29);
}

.description-list {
  margin-left: 2%;
  width: 96%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.description-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease;
}

.description-item:hover {
  background-color: #f7f7f7;
}

.description-label {
  width: 266px;
  color: #383838;
  text-align: left;
}

.description-value {
  flex-grow: 1;
  text-align: left;
}

table {
  border-collapse: collapse;
  width: 96%;
  font-family: Arial;
  font-size: 15px;
}

th, td {
  text-align: left;
  padding: 11px 8px;
}

th {
  background-color: rgba(8, 28, 75, 0.71);
  color: white;
}

tr:nth-child(even) {
  background-color: #efefef;
}

.niam {
  /*justify-content: center;*/
  background-color: #fdfcfc;
  position: absolute;
  top: 6%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

button {
  padding: 2px 5px;
  cursor: pointer;
}

</style>
