<template>
  <el-container style="background-color: #f3f3f3;position: absolute;left: 0;right: 0;">
    <el-header>
      <Header></Header>
    </el-header>
    <el-main style="background-color: #ececec">
      <br><br>
      <div>
        <h3 style="text-align: center;font-family: Arial;font-size: 23px;font-weight: bold;color: #474750">Collection Of Nanobody</h3>
        <table style="width: 50%;margin-left: 25%">
          <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Content</th>
            <th>Download</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Nanobody</td>
            <td>The basic information of nanobody entries, such as the release date, description, and authors of the
              nanobody entries.
            </td>
            <td>
              This includes PDB ID (Protein Data Bank Identification), total structure weight, atom count, modelled residue count,
              deposited residue count, molecule, method, sequence, source species, and other information
            </td>
            <td><a class="a1" href="/download/Nanobody.xlsx" download>Nanobody.xlsx</a></td>
          </tr>
          <tr>
            <td>Ligand Binding Site</td>
            <td>The information of binding sites between small molecules, drugs, metabolites, or other biomolecules and
              proteins.
            </td>
            <td>
              Concerning the ligand-receptor interactions in nanobodies, we extract relevant information,
              including the identification of ligands and receptors, binding sites, etc.
              This aids in a deeper understanding of the nanobodies' functionality and activity in specific environments.
            </td>
            <td>&nbsp;<a class="a1" href="/download/Ligand_Binding_Sites.xlsx" download>Ligand_Binding_Sites.xlsx</a>
            </td>
          </tr>
          <tr>
            <td>Active Residue</td>
            <td>The information of the region on the molecular structure of nanobodies that binds to the target
              molecule.
            </td>
            <td>
              We record the positions of active residues and the information about the sequences in which these residues are located.
            </td>
            <td><a class="a1" href="/download/Acrive_Residue.xlsx" download>Acrive_Residue.xlsx</a></td>
          </tr>
          <tr>
            <td>SARS-COV-2</td>
            <td>The complex of nanobodies and the novel coronavirus.</td>
            <td>
              Due to the widespread epidemic of COV19 around the world, in order to help researchers obtain data more conveniently, we separately screened out SARS-COV-2 related Nanobodies and complexes among all Nanobodies.
            </td>
            <td><a class="a1" href="/download/SARS-COV-2.xlsx" download>SARS-COV-2.xlsx</a></td>
          </tr>
          <tr>
            <td>Sequence</td>
            <td>The sequence information of nanobody with cdr1,cdr2,cdr3 entries</td>
            <td>
              We meticulously document the sequence information of nanobodies, encompassing amino acid sequences and the variable
              region amino acid sequences of nanobodies. This information is crucial for studying nanobodies and is key data for research
              such as nanobody screening and nanobody structure prediction models.
            </td>
            <td><a class="a1" href="/download/Sequence.xlsx" download>Sequence.xlsx</a></td>
          </tr>
          </tbody>
        </table>
      </div>

      <div>
        <br><br><br><br>
        <div>
          <div style="text-align: center">
            <a class="a2" href="/download/Screening%20Nano%20Full.csv" download
               style="font-weight: normal;text-align: center">Anti-SARS-CoV-2
              Nanobody Screening Results List (All)</a>
          </div>

          <div style="margin-top:19px;width: 66%;margin-left: 17%">

            <p style="line-height: 1.9;width:86%;margin-left:12%;font-size: 18px;font-family:Arial, sans-serif">
            <span style="font-size: 22px">
              "
            </span>
              We screened the nanobodies and got the results table. In the table, we list the source of these
              nanobodies, PDBID, the best haddock score, and their margin of error. The lower the score, the better the
              conformation.
              <span style="font-size: 22px">
            "
            </span>
              &nbsp;&nbsp;&nbsp; - - written by <i style="font-size: 19px">Wenyuan Shang</i>
            </p>
          </div>
          <div style="margin-top: -8px;">
            <el-button type="default" style="width:33%;font-size:18px;margin-left:33%;background-color:#0a8f00; color: whitesmoke" >
              <a class="no" href="/download/Screening%20Nano%20Full.csv" download
                 style="font-weight: normal;text-decoration:none;text-align: center;color: whitesmoke">Screening Nano Full.csv</a>
            </el-button>
          </div>
        </div>
      </div>
      <br><br><br><br><br>
      <div>
        <div>
          <div style="text-align: center">
            <a class="a2" href="/download/Screening%20Nano50.csv" download
               style="font-weight: normal;text-align: center">Anti-SARS-CoV-2 Nanobody Screening Results List (50)</a>
          </div>

          <div style="width: 66%;margin-left: 17%;margin-top:19px;">

            <p style="line-height: 1.9;width:86%;margin-left:12%;font-size: 18px;font-family:Arial, sans-serif">
            <span style="font-size: 20px">
              "
            </span>
              We screened out the top 50 excellent
              nanobodies. In the table, we list the source of these nanobodies,
              PDBID, the best haddock score, and their margin of error.
              The lower the score, the better the conformation.
              <span style="font-size: 20px">
            "
            </span>
              &nbsp;&nbsp;&nbsp; - - written by <i>Wenyuan Shang</i>
            </p>
          </div>
          <div style="margin-top: -8px;">
            <el-button type="default" style="width:33%;font-size:18px;margin-left:33%;background-color:#0a8f00; color: whitesmoke" >
              <a class="no" href="/download/Screening%20Nano50.csv" download
                 style="font-weight: normal;text-decoration:none;text-align: center;color: whitesmoke">Screening Nano50.csv</a>
            </el-button>
          </div>
        </div>
      </div>

      <br><br>
      <br><br>
      <br><br><br>

    </el-main>

    <el-footer>
      <Footer></Footer>
    </el-footer>
  </el-container>
</template>

<script>
import Footer from "@/views/Index/Footer.vue";
import Header from "@/views/Index/Header.vue";
import * as XLSX from 'xlsx'
import axios from "axios";
import {saveAs} from 'file-saver'

export default {
  name: "Download",
  components: {Header, Footer},
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>

.a2 {
  font-family: Arial;
  font-size: 23px;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  color: #022ff1;
}

.a2:hover {
  color: #e74c3c;
}


.a1 {
  font-size: 16px;
  transition: color 0.1s ease-in-out;
}

.a1:hover {
  color: #e74c3c;
}

table {
  border: 1px solid #9d9d9d;
  border-collapse: collapse;
  font-family: Arial;
  font-size: 14px;
}

th, td {
  border: 1px solid #9d9d9d;
  text-align: left;
  padding: 11px 8px;
}

th {

  border: 1px solid #9d9d9d;
  background-color: rgba(115, 121, 133, 0.81);
  color: white;
}

tr:nth-child(odd) {
  background-color: rgba(232, 231, 231, 0.92);
}
</style>
