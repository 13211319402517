<template>
  <div class="common-layout">

    <el-container>
      <el-header>
        <Header></Header>
      </el-header>
      <el-container class="niam">
        <el-button v-show="!showAside" @click="toggleAside" style="
                    color: rgba(0,0,0,0.7);
                    font-weight: lighter;
                    height: 80px;
                    width: 1px;
                    margin-top: 20%;
                    border: none;
                    background: rgba(231,231,231,0.72)">
          <el-icon>
            <ArrowRight/>
          </el-icon>
        </el-button>
        <el-aside v-if="showAside" class="homeAside">
          <el-menu router
                   background-color="#f8f9fd">
            <!-- eslint-disable vue/no-use-v-if-with-v-for -->
            <el-sub-menu :index="index"
                         v-for="(item,index) in routes"
                         :key="index">
              <template #title>
                <span style="margin-left: 6px;color:#335981;font-family: Arial;font-size: 16px;">{{ item.name }}</span>
              </template>
              <el-menu-item
                  :index="child.path"
                  v-for="(child,indexj) in item.children"
                  :key="indexj"
                  style="font-size: 15px;color:#335981;font-family: Arial;font-weight: bolder;">{{ child.name }}
              </el-menu-item>
            </el-sub-menu>
          </el-menu>
        </el-aside>
        <el-button v-show="showAside" @click="toggleAside" style="
                    color: rgba(0,0,0,0.7);
                    border: none;
                    font-weight: lighter;
                    height: 10%;
                    width: 2px;
                    font-size: 18px;
                    margin-top: 20%;background: rgba(245,245,245,0.59);">
          <el-icon>
            <ArrowLeft/>
          </el-icon>
        </el-button>
        <el-main class="homeMain">
          <el-breadcrumb separator=">>" v-if="$data.routee.currentRoute.path!='/home'">
            <el-breadcrumb-item :to="{ path: '/home' }">Center</el-breadcrumb-item>
            <el-breadcrumb-item>{{ $data.routee.currentRoute.name }}</el-breadcrumb-item>
          </el-breadcrumb>
          <div v-if="$data.routee.currentRoute.path=='/home'">
            <div style="display: flex;justify-content: left;">
              <p style="line-height: 1.8;width: 50%;font-family: Arial, sans-serif;font-size: 18px;">
                In the "User's Page" we provided several functional entry points. You can click on the sidebar to view
                specific functionalities.
                For example, in the "Personal Center," under 'Personal Information,' you can modify your login password.
                Additionally, if needed, you can upload your own discoveries on the 'Upload' page. We will review the
                uploaded content, and during this period,
                you can track the review progress in the "Review List."
                In the "Operation Log," you can view recent operation records, including "Upload Record," "Download Record,"
                and "Search Record."
              </p></div>
          </div>
          <router-view style="margin-left: 1%;margin-top:2%;width: 95%"></router-view>

        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import {getRequest} from "@/utils/api";
import {useRouter} from 'vue-router'
import Header from "@/views/Index/Header.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: {Header},
  data() {
    return {
      user: JSON.parse(window.sessionStorage.getItem("user")),
      routee: useRouter(),
      showAside: true,
    }
  },
  computed: {
    routes() {
      return this.$store.state.routes;
    },
  },
  mounted() {

  },
  methods: {
    logout() {
      getRequest('/logout');
      window.sessionStorage.removeItem("user");
      this.$router.push({name: "Login"})
      this.$store.commit('initRoutes', [])
    },
    toggleAside() {
      this.showAside = !this.showAside;
    }
  }
}
</script>

<style scoped>
.niam {
  background-color: #ffffff;
  position: absolute;
  top: 7%;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  width: 100%;
}

.homeAside {
  margin-left: 0%;
  margin-top: 0%;
  border-color: #f60101;
  border-width: 1px;
  border-radius: 3px;
  width: 266px;
  height: 100%;
  box-shadow: 1px 2px 1px 2px rgba(0, 11, 33, 0.4);
}

.homeMain {
  margin-left: 2%;
  margin-top: 1%;
}

</style>
