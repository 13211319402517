<template>
  <footer class="footer" style="font-family: Arial">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div style="display: flex">
            <img src="@/assets/logo1.png" alt="公司Logo" width="103" height="150">&nbsp;&nbsp;&nbsp;
            <div style="margin-left: 10px;margin-top: 18px;color: #ffffff;font-family: Arial;">
              <p style="font-size: 25px;">NanoLAS</p>
              <p style="font-size: 25px;margin-top: -16px">Database</p>
            </div>
          </div>
        </div>&nbsp;&nbsp;&nbsp;
        <div class="col-md-4">
          <p style="font-size: 23px;margin-left: -25px;padding: 16px 10px 10px 10px;color: white">    NanoLAS for nanobody</p>
<!--          <p style="color: #efefef">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;© 2023 NanoLAS</p>-->
          <p style="color: #efefef;margin-left: -61px;">Copyright © 2023 NanoLAS . All Rights Reserved.</p>
          <p style="color: #000000;margin-left:60px">You are visitor No.{{views}}</p>
        </div>
        <div class="col-md-4" style="margin-top: -10px;font-family: Arial;">
          <h4 style="color: #efefef;">Contact</h4>
          <ul style="margin-top: -10px;">
            <li style="padding:3px;width: 500px;line-height: 1.4;">Address： Building C-1, No. 3002, Lantian Road, Pingshan District, Shenzhen, Guangdong, China</li>
            <li style="padding:3px">Phone： +86 0755-23256054</li>
            <li style="padding:3px;width: 500px">E-mail： wangxin@sztu.edu.cn , xiongshuchang2020@gmail.com</li>
          </ul>
        </div>
      </div>
      <hr style="width: 136%;margin-top: 20px;margin-left: -18%;color: white"/>
<!--      <br/>-->
    </div>
  </footer>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer"
}
</script>

<script setup>
console.log(1)
import {getView} from "@/utils/menus.js"
import {createView} from "@/utils/menus.js"
import {ref} from "vue"
var formdata=new FormData()
var views=ref(0)

formdata.append("user_agent",navigator.userAgent)
formdata.append("visited_url",window.location.href)
formdata.append("ip_address","")
createView(formdata)
getView().then(res=>{
  views.value=res['access_logs'].length
})

</script>

<style scoped>
.footer {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  position: absolute;
  bottem: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  padding: 23px 0 16px 0;
  transition: all 0.3s ease-in-out;
  background-color: rgb(90, 103, 141);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.row {
  width: 110%;
  display: flex;
  flex-wrap: wrap;
}

.col-md-4 {
  flex: 0 0 32.333%;
  max-width: 32.333%;
}

h4 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #b9b9b9;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  color: white;
}

@media (max-width: 767px) {
  .row {
    flex-direction: column;
  }

  .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .footer img {
    margin-bottom: 10px;
  }
}
</style>
