<template>
  <div>
    <el-table :data="downHis" style="width: 469px;font-size: 15px;" stripe border>
      <el-table-column label="SN" width="166" align="center">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="pid" label="PID" align="center" width="303">
        <template v-slot="{ row }">
          <el-link :href="`#/show?pid=${row.pid}`" style="color: blue;">{{ row.pid }}</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 2%;margin-left: 1%;">
      <el-pagination
          :current-page="currentPage"
          :page-sizes="total"
          :page-size="size"
          @size-change="handleSizeChange"
          layout="total, sizes,  prev,  pager,  next,  jumper"
          :total="total"
          @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import {getRequest} from "@/utils/api";
export default {
  name: "DownLoadHistory",
  data() {
    return {
      downHis: [],
      page: 1,
      total: '',
      size: 15,
      currentPage: 1,
    }
  },
  mounted() {
    this.getDownHis();
  },
  methods: {
    // 改变当前页码时的回调
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.getDownHis();
    },
    handleSizeChange(newSize) {
      this.size = newSize;
      this.getDownHis();
    },
    getDownHis() {
      getRequest('/history/download?page=' + this.currentPage + "&&size=" + this.size).then(resp => {
        if (resp) {
          this.downHis = resp.data;
          this.total = resp.total;
          if(this.total < this.size){
            this.size = this.total;
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.el-pagination {
  position: static;
  margin: 23px auto 0;
  text-align: center;
  background-color: #0000;
}
</style>