<template>
  <el-container style="background-color: #f3f3f3;position: absolute;left: 0;right: 0;">
    <el-header>
      <Header></Header>
    </el-header>
    <el-main class="main">
      <div
          style="justify-content: left;display: flex;border-bottom: black 0px solid;margin-top: 18px;font-size: 19px;">
        <h1 style="color:  #6a4760;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SARS-COV2</h1>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src="@/assets/Index/cov19.png" height="100">
      </div>
      <br>
      <el-pagination
          style="margin-left: 5%"
          pager-count="15"
          background
          :current-page="currentPage"
          :page-size="size"
          :page-sizes="[15,30,60,120,240]"
          @size-change="handleSizeChange"
          :total="total"
          layout="prev,  pager,  next, sizes,  jumper,  total"
          @current-change="handleCurrentChange"
      ></el-pagination><br>

      <el-table
          ref="table"
          :data="covList"
          highlight-current-row
          style="width: 100%;">
        <el-table-column label="Pid" width="233" align="center">
          <template #default="scope">
            <el-button type="text" @click="this.$router.push('/show?pid='+scope.row.pid)">{{ scope.row.pid }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="description" label="Description"/>
        <el-table-column prop="releaseDate" label="Release Date" width="188"/>
        <el-table-column prop="author" label="Author"/>
      </el-table><br>
      <el-pagination
          style="margin-left: 5%"
          pager-count="15"
          background
          :current-page="currentPage"
          :page-size="size"
          :page-sizes="[15,30,60,90,200]"
          @size-change="handleSizeChange"
          :total="total"
          layout="prev,  pager,  next, sizes,  jumper"
          @current-change="handleCurrentChange"
      ></el-pagination><br><br><br><br>

    </el-main>
    <el-footer>
      <Footer></Footer>
    </el-footer>
  </el-container>
</template>

<script>
import Header from "@/views/Index/Header.vue";
import {getRequest} from "@/utils/api";
import Footer from "@/views/Index/Footer.vue";

export default {
  name: "SarsCov2",
  components: {Footer, Header},
  data() {
    return {
      covList: [],
      currentPage: 1,
      size: 15,
      total: 0
    }
  },
  mounted() {
    // getRequest('/sarscov2/1').then(resp => {
    //   if (resp) {
    //     this.covList = resp;
    //     // console.log(this.covList)
    //   }
    // })
    this.getCovListByPageSize();
  },
  methods:{
    handleSizeChange(newSize){
      this.size = newSize;
      this.getCovListByPageSize();
    },
    handleCurrentChange(newPage){
      this.currentPage = newPage;
      this.getCovListByPageSize();
    },
    getCovListByPageSize(){
      getRequest("/sarscov2/covList?page="+this.currentPage+"&size="+this.size).then(resp=>{
        if(resp){
          this.covList = resp.data;
          this.total = resp.total;
        }
      })
    }
  }
}
</script>

<style scoped>

:deep(.el-pagination.is-background .el-pager li:not(.is-disabled).is-active) {
  background-color: rgb(127, 134, 217) !important;
}

/deep/ .el-table thead tr > th {
  color: #000764;
  background-color: #f3f3f3;
  /*color: #fbbc07;*/
}

/* 更改表格每行背景颜色*/
/deep/ .el-table tbody tr > td {
  color: #2d4259;
  background-color: #f2f2f2;
}

/* 设置鼠标经过时背景颜色*/
/deep/ .el-table tbody tr:hover > td {
  background-color: #FFFFF0 !important;
}

.search-box input[type="text"] {
  width: 40%;
  margin-left: 27%;
  height: 40px;
  padding: 5px 10px;
  font-size: 15px;
  border: none;
  border-radius: 3px;
  outline: none;
  box-shadow: 0 0 5px #ccc;
  transition: all 0.3s ease-in-out;
}

.search-box input[type="text"]:focus {
  /*width: 250px;*/
  box-shadow: 0 0 10px #a6a4a4;
}



</style>
