<template>
  <el-container style="background-color: #f3f3f3;position: absolute;left: 0;right: 0;">
    <el-header>
      <Header></Header>
    </el-header>
    <el-main class="main">
      <div class="left-img-box" style="float: left;margin-left:100px;margin-top: 160px;">
        <img src="@/assets/new/nb.png">
      </div>

      <div class="search-box">
        <div style="justify-content: center;display: flex;border: none;margin-top: 30px;">
          <div style="font-size: 56px;font-family: Arial;color: #6a4760"><p>Nanobody Search&nbsp;&nbsp;&nbsp;&nbsp;</p>
          </div>
        </div>
        <div style="display: flex;margin-bottom: 66px;margin-left: 29%">
          <button
              style="height:51px;width: 99px;border: slategrey 1px solid;background-color: #8b959b;border-radius: 1px;color: white;font-size: 16px;">
            Pid
          </button>
          <input type="text" placeholder="pid" v-model="pid" @keydown.enter="pidListSearch" style="width: 688px">
          <img src="@/assets/search.png" width="24" height="24"
               style="margin-left:35px;margin-top:6px;background-color: rgba(224,224,224,0.6);border-radius:5px;padding:12px;cursor:pointer;"
               @click="pidListSearch">
        </div>
        <div style="display: flex;margin-bottom: 66px;margin-left: 29%">
          <button
              style="height:51px;width: 99px;border: slategrey 1px solid;background-color: #8b959b;border-radius: 1px;color: white;font-size: 16px;margin-right: 0px;">
            Description
          </button>
          <input type="text" placeholder="description" v-model="desc" @keydown.enter="descListSearch"
                 style="width: 688px">
          <img src="@/assets/search.png" width="24" height="24"
               style="margin-left:35px;margin-top:6px;background-color: rgba(224,224,224,0.6);border-radius:5px;padding:12px;cursor:pointer;"
               @click="descListSearch">
        </div>
        <div style="display: flex;margin-bottom: 66px;margin-left: 29%">
          <button
              style="height:51px;width: 99px;border: slategrey 1px solid;background-color: #8b959b;border-radius: 1px;color: white;font-size: 16px;margin-right: 0px;">
            Organism
          </button>
          <input type="text" placeholder="organism" v-model="org" @keydown.enter="orgListSearch" style="width: 688px">
          <img src="@/assets/search.png" width="24" height="24"
               style="margin-left:35px;margin-top:6px;background-color: rgba(224,224,224,0.6);border-radius:5px;padding:12px;cursor:pointer;"
               @click="orgListSearch">
        </div>
        <div style="display: flex;margin-bottom: 13px;margin-left: 29%">
          <button
              style="height:51px;width: 99px;border: slategrey 1px solid;background-color: #8b959b;border-radius: 3px;color: white;font-size: 16px;margin-right: 0px;">
            Compare
          </button>
          <input type="text" placeholder="pid-1" v-model="comparePid1" style="width: 334px;margin-left: 0px;"
                 @keydown.enter="comListSearch">
          <input type="text" placeholder="pid-2" v-model="comparePid2" style="width: 334px;margin-left: 0px"
                 @keydown.enter="comListSearch">
          <img src="@/assets/search.png" width="24" height="24"
               style="margin-left:35px;margin-top:6px;background-color: rgba(224,224,224,0.6);border-radius:5px;padding:12px;cursor:pointer;"
               @click="comListSearch">
          <br>
        </div>
        <div style="margin-left: 450px;margin-top: 45px;">
        <span style="font-family: Arial;font-size: 17px;color: #626262"> * Search for the basic information,such as total structure weight, atom count, modelled residue count,
          <br> &nbsp;&nbsp;deposited residue count, molecule, etc.</span>

        </div>
      </div>
      <div class="left-img-box" style="float: right;margin-right: 50px;margin-top: -470px">
        <img src="@/assets/new/nb2.png">
      </div>
      <br><br>
    </el-main>
    <br><br><br><br><br><br><br>
    <el-footer>
      <Footer></Footer>
    </el-footer>
  </el-container>
</template>

<script>
import Header from '@/views/Index/Header.vue'
import {ElMessage} from "element-plus";
import Footer from "@/views/Index/Footer.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Search",
  components: {Footer, Header},
  data() {
    return {
      pid: 'eg: 1i3u',
      desc: 'eg: nanobody',
      org: 'eg: Vicugna pacos',
      comparePid1: 'eg: 1i3u',
      comparePid2: 'eg: 1op9',
      headerHeight: '',
      searchType: 'pid',
      searchTypeList: [{label: 'Pid', value: 'pid'}, {label: 'Description', value: 'description'}, {
        label: 'Organism',
        value: 'organism'
      }, {label: 'Compare', value: 'compare'}]
    }
  },
  computed: {
    showInput() {
      return this.searchType == 'compare';
    }
  },
  methods: {
    toListSearch(type) {
      if (this.keyword && this.keyword != '' && this.compareKeyword && this.compareKeyword != '') {
        window.location.href = '/?#/compare?pid1=' + this.keyword + '&&pid2=' + this.compareKeyword;
      } else {
        if (type == 'compare') {
          window.location.href = '/?#/list?searchType=pid' + '&&keyword=' + this.keyword;
        } else {
          window.location.href = '/?#/list?searchType=' + type + '&&keyword=' + this.keyword;
        }
      }

      this.keyword = '';
      this.compareKeyword = '';
    },
    pidListSearch() {
      let pid = this.pid;
      if (this.pid == 'eg: 1i3u') {
        pid = '1i3u'
      }
      this.pid = 'eg: 1i3u';
      window.location.href = '?#/list?searchType=pid' + '&&keyword=' + pid;
    },
    descListSearch() {
      let desc = this.desc;
      if (this.desc == 'eg: nanobody') {
        desc = 'nanobody'
      }
      this.desc = 'eg: nanobody';
      window.location.href = '?#/list?searchType=description' + '&&keyword=' + desc;
    },
    orgListSearch() {
      let org = this.org;
      if (this.org == 'eg: Vicugna pacos') {
        org = 'Vicugna pacos'
      }
      this.org = 'eg: Vicugna pacos';
      window.location.href = '?#/list?searchType=organism' + '&&keyword=' + org;
    },
    comListSearch() {
      if (this.comparePid1 && this.comparePid2 && this.comparePid1 != '' && this.comparePid2 != '') {
        let comparePid1 = this.comparePid1;
        let comparePid2 = this.comparePid2;
        if (this.comparePid1 == 'eg: 1i3u') {
          comparePid1 = '1i3u'
        }
        if (this.comparePid2 == 'eg: 1op9') {
          comparePid2 = '1op9'
        }

        window.location.href = '/?#/compare?pid1=' + comparePid1 + '&&pid2=' + comparePid2;
      } else {
        ElMessage.error('please check you enter');
      }
    }
  }
}
</script>

<style scoped>

option {
  color: black;
  background: whitesmoke;
  font-family: 微软雅黑;
  font-weight: lighter;
}

select:hover,
select:focus {
  border-color: #c4c3c3;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

select {
  cursor: pointer;
  background-color: rgba(222, 222, 222, 0.53);
  color: #000000;
  margin-left: 22%;
  width: 126px;
  height: 49px;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  margin-right: 23px;
  border: #e3e3e3 solid 1px;
  box-shadow: 0 0 5px #dadada;
  border-radius: 1px;
}


/**/
.button-el {
  cursor: pointer;
  position: relative;
  margin-left: 22%;
  width: 126px;
  height: 50px;
  border: none;
  padding: 5px 0px;
  border: 1px rgba(51, 89, 129, 0.9);
  border-radius: 6px;
  font-size: 16px;
  background-color: rgba(51, 89, 129, 0.86);
  color: white;
}

.button-el::after {
  content: "";
  position: absolute;
  top: 40px;
  right: 8px;
  width: 83%;
  height: 2px;
  /*border-radius: 50%;*/
  background-color: #ffffff;
  transform: scale(0);
  transition: transform 0.2s ease-in-out;
}

.button-el:hover::after {
  transform: scale(1);
}

.button-el1 {
  cursor: pointer;
  position: relative;
  margin-left: 22%;
  height: 50px;
  width: 124px;
  padding: 5px 10px;
  border: 1px red;
  border-radius: 6px;
  font-size: 18px;
  background-color: rgba(255, 0, 0, 0.94);
  color: white;
}

.button-el1::after {
  content: "";
  position: absolute;
  top: 40px;
  right: 8px;
  width: 83%;
  height: 2px;
  /*border-radius: 50%;*/
  background-color: #ffffff;
  transform: scale(0);
  transition: transform 0.2s ease-in-out;
}

.button-el1:hover::after {
  transform: scale(1);
}

.search-box {
  margin-left: 100px;
  width: 80%;
}

.search-box2 input[type="text"] {
  background-color: rgba(203, 217, 208, 0.1);
  width: 40%;
  height: 40px;
  padding: 5px 10px;
  font-size: 15px;
  border: none;
  border-radius: 1px;
  outline: none;
  box-shadow: 0 0 5px #ccc;
  transition: all 0.3s ease-in-out;
}

.search-box2 input[type="text"]:focus {
  /*width: 250px;*/
  box-shadow: 0 0 10px #837e7e;
}

.search-box2 {
  width: 100%;
  margin-left: 23%;
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.search-box input[type="text"] {
  width: 40%;
  height: 40px;
  padding: 5px 10px;
  font-size: 15px;
  border: none;
  border-radius: 1px;
  outline: none;
  box-shadow: 0 0 5px #ccc;
  transition: all 0.3s ease-in-out;
}

.search-box input[type="text"]:focus {
  /*width: 250px;*/
  box-shadow: 0 0 10px #a6a4a4;
}

.niam {
  /*justify-content: center;*/
  background-color: #fdfcfc;
  position: absolute;
  top: 6%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

</style>
