import { createStore } from 'vuex'

const store = createStore({
    state:{
        // your state
        routes:[]
    },
    mutations: {
        // your mutations
        initRoutes(state,data){
            state.routes = data
        }
    },
    actions: {
        // your actions
    },
    getters: {
        // your getters
    }
})

export default store