<template>
  <Form v-show="isShowRegister">
    <div style="display: flex;margin-left: 30%;">
      <h3 class="register" @click="toggleRegister">Login</h3>
      <h3 style="color: rgba(141,138,138,0.47);">&nbsp;/&nbsp;</h3>
      <h3 style="color: #5264AE;">Register</h3>
    </div>
    <div class="input-container">
      <input type="text" id="Username" v-model="registerForm.username" required placeholder=" "
             @keydown.enter="register">
      <span></span>
      <label for="Username">Username</label>
      <p v-if="!validateUsername(registerForm.username)&&registerForm.username!=''&&registerForm.username!=null"
         class="error">
        Please enter a combination of 8 to 18 digits of letters and numbers.</p>
    </div>
    <div class="input-container">
      <input type="password" id="password" v-model="registerForm.password" required placeholder=" "
             @keydown.enter="register">
      <span></span>
      <label for="password">Password</label>
      <p v-if="!validatePassword(registerForm.password)&&registerForm.password!=null&&registerForm.password!=''"
         class="error">
        The password should not be less than 8 characters and must contain at least one number and one special character.</p>
    </div>
    <div class="input-container">
      <input type="password" id="confirmPassword" v-model="registerForm.confirmPassword" required placeholder=" "
             @keydown.enter="register">
      <span></span>
      <label for="confirmPassword">Confirm Password</label>
      <p v-if="!validateConfirmPassword(registerForm.confirmPassword)&&registerForm.confirmPassword!=null&&registerForm.confirmPassword!=''"
         class="error">
        The passwords entered twice do not match, please re-enter.</p>
    </div>
    <div class="input-container">
      <input type="email" id="email" v-model="registerForm.email" required placeholder=" " @keydown.enter="register">
      <span></span>
      <label for="email">Email</label>
      <p v-if="!validateEmail(registerForm.email)&&registerForm.email!=null&&registerForm.email!=''" class="error">
        The email format is incorrect. Please enter a valid email address.</p>
    </div>
    <button type="submit" @click="register">Register</button>
  </Form>
</template>

<script>
import {postKeyValueRequest} from "@/utils/api";

export default {
  name: "RegisterForm",
  props: {
    isShowRegister: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      registerForm: {
        username: '',
        password: '',
        email: '',
        confirmPassword: '',
      },
    };
  },
  methods: {
    register() {
      if (this.validate()) {
        this.$emit('toggle-loading', true);
        postKeyValueRequest('/register', this.registerForm).then(resp => {
          if (resp) {
            this.toggleRegister();
          }
        }).finally(() => {
          // 隐藏全屏loading
          this.$emit('toggle-loading', false);
        });
      }
    },
    toggleRegister() {
      this.$emit('toggle-register', !this.isShowRegister);
    },
    validate() {
      // 对用户名、密码和确认密码输入框进行校验
      let username = this.registerForm.username;
      let password = this.registerForm.password;
      let confirmPassword = this.registerForm.confirmPassword;
      let email = this.registerForm.email;
      if (!this.validateUsername(username)) {
        return false;
      }
      if (!this.validatePassword(password)) {
        return false;
      }
      if (!this.validateConfirmPassword(confirmPassword)) {
        return false;
      }
      if (!this.validateEmail(email)) {
        return false;
      }
      return true;
    },
    validateUsername(username) {
      // 对用户名输入框进行验证，返回 true 表示验证通过，false 表示验证失败
      const usernameRegex = /^[a-zA-Z0-9]{6,18}$/;
      return usernameRegex.test(username);
    },
    validatePassword(password) {
      // 对密码输入框进行验证，返回 true 表示验证通过，false 表示验证失败
      const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[a-zA-Z\d!@#$%^&*()_+]{8,}$/;
      return passwordRegex.test(password);
    },
    validateConfirmPassword(confirmPassword) {
      // 对确认密码输入框进行验证，返回 true 表示验证通过，false 表示验证失败
      return confirmPassword === this.registerForm.password;
    },
    validateEmail(email) {
      // 对 email 输入框进行验证，返回 true 表示验证通过，false 表示验证失败
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(email);
    }
  }
}
</script>


<style scoped>

.full-screen-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* 使 loading 在最上层 */
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.register:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  form {
    padding: 20px;
    max-width: 100%; /* Change the maximum width to 100% for smaller screens */
  }

  button[type="submit"] {
    margin-top: -6%;
    margin-left: 10%;
    /*padding: 5px 15px;*/
    background-color: #5264AE;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 17px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 84%;
  }
}

form {
  margin-left: 25%;
  margin-top: 1%; /* change to a smaller value */
  margin-bottom: 2%;
  height: 79%;
  width: 26%;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.error {
  font-size: 12px;
  color: red;
  height: 12px; /* add a fixed height */
  position: absolute; /* set position to absolute */
}

.input-container {
  position: relative;
  margin: 45px auto;
  width: 79%;
  height: 48px; /* add a fixed height */
}

button[type="submit"] {
  margin-top: -3%;
  margin-left: 10%;
  padding: 10px 25px;
  background-color: #5264AE;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 84%;
}

button[type="submit"]:hover {
  background-color: #46528C;
}

input {
  font-size: 16px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
}

input:focus {
  outline: none;
}

label {
  color: #999;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
}

input:focus ~ label,
input:not(:placeholder-shown) ~ label {
  top: -20px;
  font-size: 16px;
  color: #5264AE;
}
</style>
