<template>
  <el-container style="background-color: white;position: absolute;left: 0;right: 0;">
    <el-header>
      <Header></Header>
    </el-header>
    <!--    <el-main></el-main>-->
    <div class="contact-container">
      <div class="left-col">
        <h2>What is NanoLAS DB?</h2>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NanoLAS DB is committed to providing convenient retrieval
          services for researchers of nanobodies.
          We have collected over 20,000 sequences and more than 1,000 structural information of nanobodies
          from six authoritative open-source protein databases (RCSB, INDI, SAbDab, PDB-E, sdAb-DB, NCBI Virus),
          which are derived from dozens of organisms and various international large-scale research projects.
          We named our database NanoLAS, it could be interpreted as Nanobody Library and Archive System.
          It could also be interpreted as a database related with Nanobody, Ligand-receptor, Active residue, SARS-COV2 and nanobody related sequences.
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We provide more specialized and accurate classifications for
          this information, making it easier for
          researchers to conduct their studies. Compared to other databases, we have achieved specialization
          and high differentiation. NanoLAS integrates five retrieval methods: nanobody retrieval, nanobody
          active site retrieval, nanobody receptor-ligand retrieval, COV-SARS-2-related nanobody retrieval,
          and nanobody sequence retrieval. This will provide accurate services for researchers in different
          fields of nanobodies and greatly reduce the time they spend on information retrieval, thereby improving their
          research efficiency.
        </p>

        <img src="@/assets/about/1.png" style="max-width: 100%;margin-left:0%;height: auto;">
<br><br><br><br>


        <h2>Data species source</h2>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We classify the data in the built-in database based on the
          species of the nanoantibody source. Considering that a data entry may contain multiple immunoglobulins from
          different sources, it is possible for it to belong to multiple taxonomic categories. To address this,
          we employ a multi-source classification approach that enables simultaneous assignment of data entries to multiple categories.
        </p>
        <h3>
          Details of entries in NanoLAS DB
        </h3>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We provide more specialized and accurate classifications for
          Nanobody database contains 1281 data entries that include pdb files of nanobody data. These data are high-quality
          protein structure data that record the three-dimensional structure information of a series of nanobodies
          These nanobody data have advantages in terms of quality and uniqueness, with each data entry high resolution.
        </p>
        <img src="@/assets/about/2.png" style="max-width: 100%;margin-left:0%;height: auto;">
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   Active residue database contains 1054 nanobody data entries,
          which include information on the active sites. Active sites refer to specific regions in proteins or other
          molecules that exhibit particular biological activity and are typically composed of specific amino acid residues.
          These residues can interact with other molecules to catalyze or facilitate biochemical reactions.
        </p>

        &nbsp;&nbsp;<img src="@/assets/about/3.png" style="max-width: 100%;margin-left:0%;height: auto;">
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Ligand-receptor database contains 852 nanobody data entries, which include information on the binding sites between ligands and receptors. The binding sites refer to specific locations in protein molecules where ligands, such as drugs, hormones, and antibodies, bind to receptors, such as enzymes, receptor proteins, and transcription factors, to induce biological effects. The inclusion of these data provides important references and bases for drug development, molecular recognition, and other related research areas, facilitating a better understanding of the mechanisms of protein structure and function. Overall, these data provide powerful technical support for biomedical research and innovation.
        </p>
        <img src="@/assets/about/4.png" style="max-width: 100%;margin-left:0%;height: auto;">
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          SARS-COV-2 database contains 134 nanobody data entries that include information about the novel coronavirus. These nanobodies are a special type of antibody molecule with small molecular size and highly specific binding ability, which can precisely bind to specific sites of the novel coronavirus and prevent it from further infecting host cells.Through the analysis and research of these nanobody data, we can gain a deeper understanding of the structure and function of the novel coronavirus, providing powerful technical support for its prevention and treatment.
        </p>
        <img src="@/assets/about/5.png" style="max-width: 100%;margin-left:0%;height: auto;">
        <br><br><br><br>

        <h2>Database operation</h2>
        <h3>FAQ</h3>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The following are some operations related to the database that can help users efficiently use the database and obtain the desired nanobody data:
        </p>

        <h3>How do I:</h3>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Retrieval data:The database provides multiple retrieval methods, and users can search and filter nanobody data based on keywords, attributes, and other information. The search results can also be sorted and filtered for faster data location.
        </p>
        <img src="@/assets/about/6.png" style="max-width: 100%;margin-left:0%;height: auto;">
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Download data: Users can download nanobody data from the database to their local computer for offline use or more flexible data processing and analysis.</p>
        <img src="@/assets/about/download.png" style="max-width: 100%;margin-left:0%;height: auto;">
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Giving some useful information: The database provides various data analysis tools and algorithms, allowing users to perform in-depth exploration, statistical analysis, and visualization of nanobody data to better understand their structure and function.
        </p>
        <img src="@/assets/about/9.png" style="max-width: 100%;margin-left:0%;height: auto;">
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Upload data: Users can upload their own nanobody data to the database and share it with other researchers to promote research progress and innovation,and also we provide e-mail for them.
        </p>
        <img src="@/assets/about/upload.png" style="max-width: 100%;margin-left:0%;height: auto;">



<!--        <div>-->
<!--          <h2>Our Location</h2>-->
<!--          <p style="font-family: Arial;font-size: 18px;">Room 608, Building C-1, No. 3002, Lantian Road, Pingshan-->
<!--            District, Shenzhen, Guangdong, China-->
<!--            <br><br>广东省 深圳市 坪山区 蓝田路 3002号 C-1栋 608实验室</p>-->
<!--          <iframe-->
<!--              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3624.075021584066!2d114.39601691524566!3d22.70245173224798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403edd90ad2a2c5%3A0xb7c1a1a317f1d9a9!2sYour%20Location!5e0!3m2!1sen!2sus!4v1617722999706!5m2!1sen!2sus"-->
<!--              width="66%" height="555" style="border:0;" allowfullscreen="" loading="lazy"></iframe>-->
<!--        </div>-->
      </div>
    </div>

    <div style="height: 200px;">
    </div>
    <el-footer>
      <Footer></Footer>
    </el-footer>
  </el-container>
</template>
<script>

import Header from "@/views/Index/Header.vue";
import Footer from "@/views/Index/Footer.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Contact',
  components: {Footer, Header}
}
</script>
<style>

.left-col {
  flex-basis: 100%;
  padding-right: 20px;
}


.contact-container {
  margin-top: 15px;
  margin-left: 17%;
  width: 66%;
  display: flex;
  line-height: 2.2;
  align-items: flex-start;
}

.left-col {
  width: 100%;
}

.left-col p, .left-col ol {
  font-size: 18px;
  margin-bottom: 1.5rem;
}

.left-col h2 {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.left-col h3 {
  margin-left: 40px;
  text-align: left;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.left-col ol li {
  margin-bottom: 1rem;
}

.right-col p {
  margin-bottom: 1.2rem;
}

.right-col h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.contact-info p {
  margin-bottom: 0.5rem;
}
</style>
