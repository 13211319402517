import {getRequest} from "@/utils/api";
import {getViews} from "@/utils/api"
import {createViews} from "@/utils/api"
//后端菜单数据存放
export const initMenu = (router, store) => {
    if (store.state.routes.length > 0) {
        return;
    }
    getRequest("/system/config/menu").then(data => {
        if (data) {
            let fmtRoutes = formatRoutes(data);
            router.addRoute(fmtRoutes);
            // console.log(fmtRoutes)
            store.commit('initRoutes',fmtRoutes);
        }
    })
}
//后端数据对象化(递归)
export const formatRoutes = (routes) => {
    let fmRoutes = [];
    routes.forEach(router => {
        let {
            path,
            component,
            name,
            children
        } = router;
        if(children&&children instanceof Array){
            children = formatRoutes(children);
        }
        let fmRouter={
            path:path,
            name:name,
            children:children,
            component: ''
        }
        // fmRouter.component=resolveComponent(component)
        fmRoutes.push(fmRouter)
    })
    return fmRoutes;
}

export async function getView(){
    let res;
    await getViews().then(data =>{
        res=data
    })
    console.log("res:",res)
    return res
}

export const createView = (parmas) =>{
    createViews(parmas).then(data =>{
        console.log(data)
        var res=data
        console.log(res)
    })
}