<template>
  <div>
    <el-container style="background-color: #f3f3f3;position: absolute;left: 0;right: 0;">
      <el-header>
        <Header></Header>
      </el-header>
      <el-container class="main">
        <el-button v-show="!showAside" @click="toggleAside" style="
                    color: rgba(0,0,0,0.7);
                    font-weight: lighter;
                    height: 10%;
                    width: 1px;
                    margin-top: 20%;
                    border: none;
                    background: rgba(218,218,218,0.72)">
          <el-icon>
            <ArrowRight/>
          </el-icon>
        </el-button>
        <el-button v-show="showAside" @click="toggleAside" style="
                    color: rgba(0,0,0,0.7);
                    border: none;
                    font-weight: lighter;
                    height: 20%;
                    width: 2px;
                    margin-left: 1px;
                    margin-top: 20%;background: rgba(108,108,108,0.59);">
          <el-icon>
            <ArrowLeft/>
          </el-icon>
        </el-button>
        <el-aside v-if="showAside" class="homeAside" style="background-color: transparent;width: 20%;">
          <br><br><br><br>
          <div style="margin-left: 0px;width: 360px">
            <div class="card">
              <div style="display: flex;font-size: 24px">
                <h2>Organism</h2>
                <el-icon class="del-icon" style="margin-left: 226px;" @click="resetOrgs">
                  <Delete/>
                </el-icon>
              </div>
              <div v-for="(org,index) in selectOrgs" style="display: contents;" :key="index">
                <button class="greentag"
                        style="margin-right: 6px;margin-bottom: 5px;cursor: pointer;padding: 8px 6px;border: none;border-radius: 2px"
                        @click="addToOrgs(index)">
                  {{ org.organism }}({{ org.count }})
                </button>
              </div>
              <hr>
              <div v-for="(org,index) in sortedOrgs" style="display: contents;" :key="index">
                <button class="greytag"
                        style="margin-right: 6px;margin-bottom: 5px;cursor: pointer;padding: 8px 6px;border: none;border-radius: 2px;"
                        @click="addToSelectOrgs(index)">
                  {{ org.organism }} ({{ org.count }})
                </button>
              </div>
            </div>
            <br>
            <div class="card">
              <div style="display: flex;font-size: 24px">
                <h2>Time</h2>
                <el-icon class="del-icon" style="margin-left: 265px;" @click="resetTimes">
                  <Delete/>
                </el-icon>
              </div>
              <div v-for="(time,index) in selectTimes" style="display: contents;" :key="index">
                <button class="greentag"
                        style="margin-right: 21px;margin-bottom: 5px;cursor: pointer;padding: 6px;width:88px;border: none;border-radius: 2px;"
                        @click="addToTimes(index)">
                  {{ time.t }} ({{ time.c }})
                </button>
              </div>
              <hr>
              <div v-for="(time,index) in sortedTimes" style="display: contents;" :key="index">
                <button class="greytag"
                        style="margin-right: 27px;margin-bottom: 5px;cursor: pointer;padding: 6px;width:120px;border: none;border-radius: 2px;"
                        @click="addToSelectTimes(index)">
                  {{ time.t }} ({{ time.c }})
                </button>
              </div>
            </div>
            <br>
            <div class="card">
              <div style="display: flex;font-size: 24px">
                <h2>Unique Protein Chains</h2>
                <el-icon class="del-icon" style="margin-left: 125px;" @click="resetUpcs">
                  <Delete/>
                </el-icon>
              </div>
              <div v-for="(upc,index) in selectUpcs" style="display: contents;" :key="index">
                <button class="greentag"
                        style="margin-right: 23px;margin-bottom: 5px;cursor: pointer;padding: 6px;width:55px;border: none;border-radius: 2px;"
                        @click="addToUpcs(index)">
                  {{ upc.upc }} ({{upc.count}})
                </button>
                <br v-show="(index+1)%4==0">
              </div>
              <hr>
              <div v-for="(upc,index) in sortedUpcs" style="display: contents;" :key="index">
                <button class="greytag"
                        style="margin-right: 23px;margin-bottom: 5px;cursor: pointer;padding: 6px;width:55px;border: none;border-radius: 2px;"
                        @click="addToSelectUpcs(index)">
                  {{ upc.upc }} ({{upc.count}})
                </button>
                <br v-show="(index+1)%4==0">
              </div>
            </div>

          </div>
         <br>
        </el-aside>

        <el-main class="table-play">
          <br><br>
          <el-pagination
              pager-count="15"
              background
              :current-page="currentPage"
              :page-size="size"
              :page-sizes="[15,30,60,120,240]"
              @size-change="handleSizeChange"
              :total="total"
              layout="total, prev,  pager,  next, sizes,  jumper"
              @current-change="handleCurrentChange"
          ></el-pagination>
          <!--          </div>-->
          <br style="margin-top: 1px;">

          <table class="table-own">
            <thead>
            <tr>
              <th class="sn">SN</th>
              <th class="pdb-id">PDB-ID</th>
              <th class="description">Description</th>
              <th class="uniqueProteinChains">Unique Protein Chains</th>
              <th class="organism">Organism</th>
              <th class="releaseDate">Release Date</th>
              <th class="pdb-file">PDB-FILE&nbsp;&nbsp;&nbsp;&nbsp;</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(protein, index) in proteinList" :key="index">
              <td class="sn">{{ index+1 }}</td>
              <td class="pdb-id">
                <router-link :to="'/show?pid=' + protein.pid" class="link-style">
                  {{ protein.pid }}
                </router-link>
              </td>
              <td class="description">
                <router-link :to="'/show?pid=' + protein.pid" class="link-style2">
                  {{ protein.description }}
                </router-link>
              </td>
              <td class="uniqueProteinChains">
                {{ protein.uniqueProteinChains }}
              </td>
              <td class="organism">
                {{ protein.organism }}
              </td>
              <td class="releaseDate">
                {{ protein.releaseDate }}
              </td>
              <td class="pdb-file">
                <!--                <button type="primary" class="downloadButton" @click="download(protein.pid)">download</button>-->
                <el-button type="text" @click="download(protein.pid)">download</el-button>
              </td>
            </tr>
            </tbody>
          </table>
          <br>
          <el-pagination
              pager-count="15"
              background
              :current-page="currentPage"
              :page-size="size"
              :page-sizes="[15,30,60,120,240]"
              @size-change="handleSizeChange"
              :total="total"
              layout="total, prev,  pager,  next, sizes,  jumper"
              @current-change="handleCurrentChange"
          ></el-pagination>
        </el-main>
      </el-container>
      <br><br><br><br><br><br><br><br><br>
      <el-footer>
        <Footer></Footer>
      </el-footer>
    </el-container>
  </div>
</template>


<script>
import {getRequest} from "@/utils/api";
import Header from '@/views/Index/Header.vue'
import {ElMessage} from "element-plus";
import Footer from "@/views/Index/Footer.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "List",
  // eslint-disable-next-line vue/no-unused-components
  components: {Footer, Header},
  data() {
    return {
      keyword: this.$route.query.keyword,
      searchType: this.$route.query.searchType,
      proteinList: [],
      page: this.$route.query.page,
      total: '',
      size: 15,
      currentPage: 1,
      loading: false,
      showAside: true,
      orgs: [],
      selectOrgs: [],
      times: [{t: "1995-2000", c: 0}, {t: "2001-2005", c: 0}, {t: "2006-2010", c: 0}, {
        t: "2011-2015",
        c: 0
      }, {t: "2016-2020", c: 0}, {t: "2021-now", c: 0}],
      timeCount: null,
      selectTimes: [],
      upcs: [],
      selectUpcs: []
    }
  },
  computed: {
    sortedOrgs() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.orgs.sort((a, b) => {
        const startA = a.count;
        const startB = b.count;
        return startB - startA;
      });
    },
    sortedTimes() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.times.sort((a, b) => {
        const startA = parseInt(a.t.split('-')[0]);
        const startB = parseInt(b.t.split('-')[0]);
        return startA - startB;
      });
    },
    sortedUpcs() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.upcs.sort((a, b) => a.upc - b.upc);
    }
  },
  created() {
    if (this.page == null) {
      this.page = 1;
    }
    if (this.keyword == null) {
      this.keyword = '';
    }
    if (this.searchType == null) {
      this.searchType = 'pid';
    }
    this.getTimeCount();
    this.getOrgs();
    this.selectListByKeyword();
  },
  mounted() {
    this.getUpcs();
  },
  methods: {
    getTimeCount() {
      getRequest("/protein/timeupc/count").then(resp => {
        if (resp) {
          this.timeCount = resp.obj;
          for (let i = 0; i < this.times.length; i++) {
            this.times[i].c = this.timeCount[this.times[i].t];
          }
        }
      }).finally(()=>{
        this.getUpcs();
      })
    },
    selected() {
      if (this.selectTimes != null && this.selectTimes != '') {
        return true;
      }
      if (this.selectUpcs != null && this.selectUpcs != '') {
        return true;
      }
      if (this.selectOrgs != null && this.selectOrgs != '') {
        return true;
      }
      return false;
    },
    getAdList() {
      let url = '/protein/list/ad?upcs=';
      let upcsUrl = '';
      // console.log(this.selectUpcs)
      for (let i = 0; i < this.selectUpcs.length; i++) {
        upcsUrl += this.selectUpcs.at([i]).upc;
        if (i < this.selectUpcs.length - 1) {
          upcsUrl += ',';
        }
      }
      let orgsUrl = '&&orgs=';
      for (let i = 0; i < this.selectOrgs.length; i++) {
        orgsUrl += this.selectOrgs.at([i]).organism;
        if (i < this.selectOrgs.length - 1) {
          orgsUrl += ',';
        }
      }
      let timeUrl = '&&times=';
      for (let i = 0; i < this.selectTimes.length; i++) {
        timeUrl += this.selectTimes.at([i]).t;
        if (i < this.selectTimes.length - 1) {
          timeUrl += ',';
        }
      }
      url += upcsUrl;
      url += orgsUrl;
      url += timeUrl;
      // console.log(url)
      getRequest(url + '&&page=' + this.currentPage + '&&size=' + this.size).then(resp => {
        if (resp) {
          this.proteinList = [];
          this.proteinList = resp.data;
          this.total = resp.total;
        }
      });
    },
    resetUpcs() {
      this.getUpcs();
      this.selectUpcs = [];
      if (this.selected()) {
        this.getAdList();
      } else {
        this.selectListByKeyword();
      }
    },
    addToUpcs(index) {
      var org = this.selectUpcs.at(index);
      this.selectUpcs.splice(index, 1);
      this.upcs.push(org);
      if (this.selected()) {
        this.getAdList();
      } else {
        this.selectListByKeyword();
      }
    }
    ,
    addToSelectUpcs(index) {
      var org = this.upcs.at(index);
      this.upcs.splice(index, 1);
      this.selectUpcs.push(org);
      this.getAdList();
    }
    ,
    getUpcs() {
      getRequest('/protein/upcs').then(resp => {
        if (resp) {
          this.upcs = [{upc: 0, count: 0}, {upc: 0, count: 0}, {upc: 0, count: 0}, {upc: 0, count: 0}, {
            upc: 0,
            count: 0
          }, {upc: 0, count: 0}, {upc: 0, count: 0}, {upc: 0, count: 0}, {upc: 0, count: 0}, {
            upc: 0,
            count: 0
          }, {upc: 0, count: 0}, {upc: 0, count: 0}, {upc: 0, count: 0}, {upc: 0, count: 0}];
          let temp = resp.obj;
          for (let i = 0; i < temp.length; i++) {
            this.upcs[i].upc = temp[i];
            this.upcs[i].count = this.timeCount[temp[i]];
          }
          // console.log(this.upcs)
          for (let i = 0; i < this.upcs.length; i++) {
            if (this.upcs[i].upc == 0) {
              this.upcs.splice(i,1);
              i--;
            }
          }
          // console.log(this.upcs)
        }
      })
    },
    resetTimes() {
      this.times = [{t: "1995-2000", c: 0}, {t: "2001-2005", c: 0}, {t: "2006-2010", c: 0}, {t: "2011-2015", c: 0}, {
        t: "2016-2020",
        c: 0
      }, {t: "2021-now", c: 0}];
      this.getTimeCount();
      this.selectTimes = [];
      if (this.selected()) {
        this.getAdList();
      } else {
        this.selectListByKeyword();
      }
    },
    addToTimes(index) {
      var org = this.selectTimes.at(index);
      this.selectTimes.splice(index, 1);
      this.times.push(org);
      if (this.selected()) {
        this.getAdList();
      } else {
        this.selectListByKeyword();
      }
    }
    ,
    addToSelectTimes(index) {
      var org = this.times.at(index);
      this.times.splice(index, 1);
      this.selectTimes.push(org);
      this.getAdList();
      // console.log("123456789")
    }
    ,
    resetOrgs() {
      this.getOrgs();
      this.selectOrgs = [];
      // console.log(this.orgs, this.selectOrgs);
      if (this.selected()) {
        this.getAdList();
      } else {
        this.selectListByKeyword();
      }
    }
    ,
    addToOrgs(index) {
      var org = this.selectOrgs.at(index);
      this.selectOrgs.splice(index, 1);
      this.orgs.push(org);
      if (this.selected()) {
        this.getAdList();
      } else {
        this.selectListByKeyword();
      }
    }
    ,
    addToSelectOrgs(index) {
      var org = this.orgs.at(index);
      this.orgs.splice(index, 1);
      this.selectOrgs.push(org);
      this.getAdList();
    }
    ,
    getOrgs() {
      getRequest('/protein/orgs').then(resp => {
        if (resp) {
          this.orgs = resp.obj;
          this.halfOrgs = this.orgs.slice(0, 6);
        }
      })
    }
    ,
    toggleAside() {
      this.showAside = !this.showAside;
    }
    ,
    download(pid) {
      let dataJson = window.sessionStorage.getItem('user');
      if (!dataJson) {
        this.$router.push({name: "Login"});
      } else {
        let data = JSON.parse(dataJson);

            getRequest("/protein/download/" + pid).then(resp => {
              if (resp) {
                this.saveAsPDBFile(pid, resp.obj)
              }
            });
            return;


        ElMessage.error("check your permission!");
        this.$router.push("/user/info");
      }
    }
    ,
    saveAsPDBFile(pid, str) {
      const blob = new Blob([str], {type: 'chemical/x-pdb'});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = pid + '.pdb';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    ,
    goToIndex() {
      window.location.href = '/?#/index';
    }
    ,
    goToList() {
      window.location.href = '/?#/list';
    }
    ,
    selectListByKeyword() {
      // console.log('/protein/list?searchType=' + this.searchType + '&&keyword=' + this.keyword + '&&page=' + this.currentPage + '&&size=' + this.size)
      this.loading = true;
      getRequest('/protein/list?searchType=' + this.searchType + '&&keyword=' + this.keyword + '&&page=' + this.currentPage + '&&size=' + this.size).then(resp => {
        if (resp) {
          this.proteinList = [];
          this.total = resp.total;
          this.proteinList = resp.data;
          // console.log(this.proteinList.at(0));
        }
      }).finally(() => {
        this.loading = false;
      })
    }
    ,
// 改变每页显示的数据条数时的回调
    handleSizeChange(newSize) {
      this.size = newSize;
      if (this.selected()) {
        this.getAdList();
      } else {
        this.selectListByKeyword();
      }
    }
    ,
// 改变当前页码时的回调
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      if (this.selected()) {
        this.getAdList();
      } else {
        this.selectListByKeyword();
      }
    }
    ,

  }
}
</script>

<style scoped>

.del-icon {
  cursor: pointer;
  color: rgba(255, 0, 0, 0.8);
  margin-top: 1px;
  transition: all 0.2s ease;
}

.del-icon:hover {
  color: rgba(255, 0, 0, 0.4);
}

.greytag {
  color: #043d12;
  background-color: #e7e7e7;
  transition: all 0.2s ease;
}

.greentag {
  color: white;
  background-color: #34a634;
  transition: all 0.2s ease;
}

.greentag:hover {
  background-color: rgba(255, 0, 0, 0.86);
}

.greytag:hover {
  color: white;
  background-color: #34a634;
}

.card {
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  background-color: #fff;
  padding: 16px;
  /*height: 222px;*/
  width: 339px;
  margin: auto;
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card h2 {
  font-size: 16px;
  font-weight: bold;
  margin-top: 0;
}

.card p {
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

/* 输入框样式 */
.my-input {
  width: 300px;
  border: #496686 2px solid;
  border-radius: 5px;
  background-color: #f2f2f2;
  height: 36px;
  font-size: 18px;
  color: #333;
  /*box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);*/
  transition: all 0.3s ease;
}

.my-input:focus {
  width: 666px;
  outline: none;
  border-color: #4c5fa2;
  box-shadow: 0px 2px 10px rgba(126, 156, 255, 0.5);
}

/* 按钮样式 */
.my-button {
  margin-left: 11px;
  height: 42px;
  width: 72px;
  background-color: #496686;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.my-button:hover {
  background-color: #6772e5;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.my-button:focus {
  outline: none;
  box-shadow: 0px 2px 10px rgba(126, 156, 255, 0.5);
}

.table-play {
  width: 80%;
  margin-left: 2%;
  /*background-color: #f2f2f2;*/
}

.table-own {
  width: 90%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

.table-own thead {
  background-color: #f2f2f2;
}

.table-own th,
.table-own td {
  padding: 6px;
  text-align: left;
  /*border: 1px solid #ddd;*/
}

.table-own th.sn,
.table-own td.sn {
  text-align: center;
  /*width: 88px;*/
}

.table-own th.pdb-id,
.table-own td.pdb-id {
  text-align: center;
  /*width: 188px;*/
}

.table-own th.description,
.table-own td.description {
  text-align: left;
  width: auto;
}

.table-own th.pdb-file,
.table-own td.pdb-file {
  text-align: center;
  /*width: 158px;*/
}

.table-own tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table-own tbody tr:hover {
  background-color: #ddd;
}

.link-style {
  text-decoration: none;
  color: #007bff;
}

.link-style2 {
  text-decoration: none;
  color: #333;
}


.downloadButton {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 6px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  border-radius: 0px;
}

.downloadButton:hover {
  background-color: #0056b3;
}

:deep(.el-pagination.is-background .el-pager li:not(.is-disabled).is-active) {
  background-color: rgba(53, 111, 173, 0.93) !important;
}

.list-main {
  background-color: #f2f2f2;
  position: absolute;
  top: 7%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.link-style {
  color: rgba(0, 0, 0, 0.93);
  text-decoration: none; /* 去掉下划线 */
  position: relative; /* 让子元素相对于父元素进行定位 */
}

.link-style::before {
  content: ""; /* 添加一个伪元素作为动画效果的基本元素 */
  position: absolute;
  bottom: -3px; /* 将伪元素定位在链接文本底部 */
  width: 0%;
  height: 2px;
  /*background-color: rgba(255, 0, 0, 0.91);*/
  background-color: red;
  transition: all 0.25s ease-in-out; /* 添加所有CSS属性过渡效果 */
}

.link-style:hover::before {
  width: 180%; /* 鼠标悬停时将伪元素宽度调整到100% */
  margin-left: -39%;
}

.link-style2 {
  color: rgba(0, 0, 0, 0.93);
  text-decoration: none; /* 去掉下划线 */
  position: relative; /* 让子元素相对于父元素进行定位 */
}

.link-style2::before {
  content: ""; /* 添加一个伪元素作为动画效果的基本元素 */
  position: absolute;
  bottom: -2px; /* 将伪元素定位在链接文本底部 */
  width: 9px;
  /*height: 40px;*/
  height: 2px;
  margin-left: -1px;
  /*background-color: rgba(255, 0, 0, 0.09);*/
  transition: all 0.25s ease-in-out; /* 添加所有CSS属性过渡效果 */
}

.link-style2:hover::before {
  width: 98%; /* 鼠标悬停时将伪元素宽度调整到100% */
  margin-left: 20px;
  background-color: rgba(255, 69, 0);
  /*height: 2px;*/
}


.uniqueProteinChains {
  width: auto;
}

.organism {
  width: auto;
}

.releaseDate {
  width: auto;
}
</style>
