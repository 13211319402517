<template>
  <div class="common-layout">


    <!--  <el-container style="display: flex; flex-direction: column; height: 100%;">-->
    <el-container>
      <el-header>
        <Header></Header>
      </el-header>
      <el-main class="main">
        <div style="justify-content: center;display: flex;border-bottom: black 0px solid;">
          <img src="@/assets/11.png" width="145" height="145">
          <div style="font-size: 52px;font-family: Arial;color: #6a4760"><p>NanoLAS DB</p></div>
        </div>
        <div style="justify-content: center;display: flex;border-bottom: #eeeeee 0px solid;height: 177px;margin-top:18px;font-size: 23px;font-family: 隶书;
      background-color:rgba(229,229,229,0.09);color: rgba(0,0,0,0.94);
      width: 66%;margin-left: 17%;line-height: 1.5">
          &nbsp;&nbsp;&nbsp;&nbsp;We have collected information on nanobodies and related content from various protein
          databases. The main purpose of this database is to integrate existing data and provide more convenient search
          functions for researchers to use. We offer several convenient search functions, including the following:
        </div>
        <div
            style="justify-content: center;display: flex;border: black 0px solid;width: 66%;margin-left: 17%;box-shadow: #c9c9c9 0px 0px 0px">
          <div style="display: flex;flex-direction: column">
            <img @click="goToLink('search')" class="img1" src="@/assets/Index/base.png" width="50" height="100"
                 style="margin-left:40px;z-index: 999;">
            <button class="btn" style="background-color: rgba(73,104,131,0.8);  cursor: pointer;"
                    @click="goToLink('search')">Nanobody
            </button>
          </div>
          <div style="display: flex;flex-direction: column;margin-left: 66px;">
            <img class="img2" src="@/assets/Index/lc.png" width="120" height="60" @click="goToLink('bindSearch')"
                 style="z-index: 999;margin-left: 99px;margin-top: 17px;margin-bottom: 23px;border-radius: 16px;">
            <button class="btn" style="background-color: rgba(171,158,18,0.8);margin-left: 86px;cursor: pointer;"
                    @click="goToLink('bindSearch')">
              Ligand-receptor
            </button>
          </div>
          <div style="display: flex;flex-direction: column;margin-left: 66px;">
            <img class="img2" src="@/assets/Index/point.png" width="110" height="55"
                 @click="goToLink('/activesitesearch')"
                 style="z-index: 999;margin-left:101px;margin-top: 26px;margin-bottom: 20px;border-radius: 16px;">
            <button class="btn" style="background-color: rgba(176,115,24,0.8);margin-left: 86px;cursor: pointer;"
                    @click="goToLink('/activesitesearch')">
              Active residue
            </button>
          </div>
          <div style="display: flex;flex-direction: column;margin-left: 66px;">
            <img @click="goToLink('/sarscov2')" class="img3" src="@/assets/Index/cov19.png" width="135" height="93"
                 style="z-index: 999;margin-left:91px;margin-top: 14px;margin-bottom: -4px;border:none;">
            <button class="btn" style="background-color: rgba(82,122,36,0.76);margin-left: 86px;cursor: pointer;"
                    @click="goToLink('/sarscov2')">SARS-Cov2
            </button>
            <br>
          </div>
          <div style="display: flex;flex-direction: column;margin-left: 66px;">
            <img @click="goToLink('/sequence')" class="img3" src="@/assets/Index/img.png" width="145" height="68"
                 style="z-index: 999;margin-left:91px;margin-top: 14px;margin-bottom: -4px;border-radius: 10px;border: none"><br>
            <button class="btn"
                    style="background-color: rgba(36,119,122,0.76);margin-left: 86px;cursor: pointer;margin-top: 3px;"
                    @click="goToLink('/sequence')">Sequence
            </button>
            <br>
          </div>
        </div>
        <br>
        <table style="margin-top: 33px;border-radius: 5px;">
          <tbody>
          <tr style="background-color: rgba(0,0,0,0)">
            <td>
              <p style="color: #00590b;font-size: 26px;">1281</p>
            </td>
            <td class="tdLink">
              <p style="color: #00590b;font-size: 26px;">853</p>
            </td>
            <td class="tdLink">
              <p style="color: #00590b;font-size: 26px;">1058</p>
            </td>
            <td class="tdLink">
              <p style="color: #00590b;font-size: 26px;">140</p>
            </td>
            <td class="tdLink">
              <p style="color: #00590b;font-size: 26px;">30667</p>
            </td>
          </tr>
          <tr style="background-color: rgba(0,0,0,0);color: #00590b;">
            <td class="tdLink" style="text-align: center;padding: 15px;font-size: 18px;">
              The basic information of nanobody entries, such as the release date, description, and authors of the
              nanobody entries.
            </td>
            <td class="tdLink" style="text-align: center;padding: 15px;font-size: 18px;">
              The information of binding sites between small molecules, drugs, metabolites, or other biomolecules and
              proteins.
            </td>
            <td class="tdLink" style="text-align: center;padding: 15px;font-size: 18px;">
              The information of the region on the molecular structure of nanobodies that binds to the target molecule.
            </td>
            <td class="tdLink" style="text-align: center;padding: 15px;font-size: 18px;">
              The complex of nanobodies and the novel coronavirus.
            </td>
            <td class="tdLink" style="text-align: center;padding: 15px;font-size: 18px;">
              The sequence information of nanobody with cdr1,cdr2,cdr3 entries
            </td>
          </tr>
          </tbody>
        </table>
        <br><br><br><br><br>
        <!--      <div class="image-container">-->
        <!--        <img src="@/assets/Index/t4.png">-->
        <!--        <img src="@/assets/ Index/t1.png">-->
        <!--        <img src="@/assets/Index/t2.png">-->
        <!--        <img src="@/assets/Index/t3.png">-->
        <!--      </div>-->
        <div class="image-container">
          <img src="@/assets/Index/t3.png" style="width: 50%;"><br>
          <img src="@/assets/Index/t1.png" style="width: 80%;"><br>
          <img src="@/assets/Index/13c45589d2ccf985ae3410238e95840.png" style="width: 75%;"><br>
          <img src="@/assets/Index/t4.png" style="width: 80%;"><br>
        </div>
      </el-main>
      <el-footer>
        <Footer></Footer>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import Header from '@/views/Index/Header.vue'
import Footer from "@/views/Index/Footer.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Index",
  components: {Header, Footer},
  data() {
    return {
      countFrom: {
        total: 1285,
        part: [
          {
            name: 'RCSB',
            count: 900
          }, {
            name: 'SAbDab',
            count: 256
          }, {
            name: 'INDI',
            count: 129
          }
        ]
      }
    }
  },
  computed: {},
  mounted() {

  },
  methods: {
    goToLink(link) {
      this.$router.push(link);
    }
  }
}
</script>

<style scoped>

/*.main{*/
/*  !*position: absolute;*!*/
/*  !*top: 0;*!*/
/*  left: 0;*/
/*  z-index: 999;*/
/*  width: 100%;*/
/*  padding: 0px 0;*/
/*  transition: all 0.3s ease-in-out;*/
/*  background-color: rgba(222, 225, 232);*/
/*}*/
.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container img {
  /*width: 80%; !* 设置图片的宽度 *!*/
  height: auto; /* 设置高度自适应 */
  margin: 0 10px; /* 设置图片之间的间距 */
}

table {
  border-collapse: collapse;
  width: 82%;
  font-family: Arial;
  font-size: 15px;
  margin-left: 9%;
}

td {
  text-align: center;
  width: 10%;
}

tr:nth-child(even) {
  background-color: #efefef;
}

.img1 {
  transform: rotate(-90deg);
}

.img1:hover {
  opacity: 0.6;
  cursor: pointer;
  transform: scale(1.15) rotate(-75deg);
  transition: transform 0.2s ease-in-out;
}

.img2:hover {
  opacity: 0.6;
  cursor: pointer;
  transform: scale(1.15) rotate(15deg);
  transition: transform 0.2s ease-in-out;
}

.img3:hover {
  opacity: 0.6;
  cursor: pointer;
  transform: scale(1.15) rotate(180deg);
  transition: transform 0.6s ease-in-out;
}

table {
  border-collapse: collapse;
  box-shadow: 2px 2px 12px rgb(186, 189, 185);
}

th {
  width: 233px;
}

.btn {
  width: 145px;
  height: 66px;
  border: none;
  color: white;
  border-radius: 4px 26px 4px 26px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 19px;
  box-shadow: 0 8px 13px rgba(0, 0, 0, .3);
  transition: box-shadow .3s ease-in-out;
}

.btn:hover {
  box-shadow: 0 12px 16px rgba(0, 0, 0, .3);
}

.btn:active {
  transform: translateY(2px);
  box-shadow: none;
}

.niam {
  /*justify-content: center;*/
  background-color: #fdfcfc;
  position: absolute;
  top: 6%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

</style>
