<template>
  <div>
    <el-descriptions
        :column="1"
        border
        size=""
        class="pdb-descriptions"
    >
      <el-descriptions-item label="Pid">
        <el-input v-model="uploadPdb.pid" placeholder="eg. 1i3u" class="my-input"></el-input>
      </el-descriptions-item>
      <el-descriptions-item label="Description">
        <el-input class="my-input"
                  placeholder="eg. THREE-DIMENSIONAL STRUCTURE OF A LLAMA VHH DOMAIN COMPLEXED WITH THE DYE RR1"
                  v-model="uploadPdb.description"></el-input>
      </el-descriptions-item>
      <el-descriptions-item label="Release Date">
        <el-input class="my-input" placeholder="eg. 2001-08-08" v-model="uploadPdb.releaseDate"></el-input>
      </el-descriptions-item>
      <el-descriptions-item label="Deposition Author">
        <el-input class="my-input" placeholder="eg. Spinelli, S., Tegoni, M., Frenken, L., van Vliet, C., Cambillau, C."
                  v-model="uploadPdb.depositionAuthor"></el-input>
      </el-descriptions-item>
      <el-descriptions-item label="Total Structure Weight">
        <el-input class="my-input" placeholder="eg. 14.55 kDa" v-model="uploadPdb.totalStructureWeight"></el-input>
      </el-descriptions-item>
      <el-descriptions-item label="Atom Count">
        <el-input class="my-input" placeholder="eg. 1186" v-model="uploadPdb.atomCount"></el-input>
      </el-descriptions-item>
      <el-descriptions-item label="Modelled Residue Count">
        <el-input class="my-input" placeholder="eg. 127" v-model="uploadPdb.modelledResidueCount"></el-input>
      </el-descriptions-item>
      <el-descriptions-item label="Deposited Residue Count">
        <el-input class="my-input" placeholder="eg. 127" v-model="uploadPdb.depositedResidueCount"></el-input>
      </el-descriptions-item>
      <el-descriptions-item label="Unique Protein Chains">
        <el-input class="my-input" placeholder="eg. 1" v-model="uploadPdb.uniqueProteinChains"></el-input>
      </el-descriptions-item>
      <el-descriptions-item label="Pdb File">
        <label>
          <input type="file" id="pdb_upload" name="pdb_upload" multiple accept=".pdb" @change="handleFileChange"
                 style="display: none">
          <span style=" display: inline-block;
                        padding: 2px 10px;
                        border: 1px solid rgba(0,0,0,0.61);
                        border-radius: 2px;
                        cursor: pointer;
                        background-color: rgba(227,227,227,0.78);font-family: Arial;color: black">Select Pdb File</span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;selected:&nbsp;&nbsp;{{ this.filename }}</span>
        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </el-descriptions-item>
      <el-descriptions-item label="Action">
        <el-button @click="uploadPdbMethod" type="success">Submit</el-button>
        <el-button @click="cleanAll" type="danger">Clean</el-button>
      </el-descriptions-item>
    </el-descriptions>
    <h3>if you have more details want to share, you can send an email to 17744909861@163.com,and we will replay to you soon. </h3>
  </div>

    <!--    <el-button @click="sss"></el-button>-->

</template>

<script>
import {putRequest} from "@/utils/api";
import {ElMessage} from "element-plus";

export default {
  name: "UploadPage",
  data() {
    return {
      uploadPdb: {},
      filename: ''
    }
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.filename = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          this.uploadPdb.pdbStr = reader.result;
        };
        reader.onerror = () => {
          // console.error('文件读取失败');
        };
        reader.readAsText(file);
      } else {
        this.filename = null;
        this.uploadPdb.pdbStr = null;
      }
    },
    sss() {
      // console.log(this.uploadPdb.pdbStr);
      // console.log(this.filename);
    },
    uploadPdbMethod() {
      if (Object.keys(this.uploadPdb).length == 10) {
        if(window.confirm("Please confirm all the values are right")){
          putRequest('/upload', this.uploadPdb).then(resp => {
            if (resp) {
              // console.log(resp);
              this.filename=null;
              this.uploadPdb = {};
            }
          })
        }

      } else {
        ElMessage.error('Please Enter All Values');
      }
    },
    cleanAll(){
      this.filename='';
      this.uploadPdb={};
      ElMessage.success('ok!');
    }
  }
}
</script>

<style scoped>
.my-input {
  /*color: #000000;*/
  padding: 1px;
  width: 99%;
  /*border: black;*/
  background-color: rgba(101, 145, 190, 0.03);
  border-radius: 5px;
  height: 36px;
}

.pdb-descriptions {
  margin-top: 3%;
  background-color: rgb(235, 238, 245);
  border-radius: 3px;
  border: 1px solid rgb(235, 238, 245);
}


</style>
