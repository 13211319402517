<template>
  <el-container style="background-color: #f3f3f3;position: absolute;left: 0;right: 0;">
    <el-header>
      <Header></Header>
    </el-header>
    <el-main class="main">
      <div style="float: left;" v-show="!isSearch">
        <img src="@/assets/new/sequence.png" width="500" height="450" style="margin-left: 80px;margin-top: 198px">
      </div>
      <div style="justify-content: center;display: flex;border: none;margin-top: 40px;"  v-show="!isSearch">
        <div style="font-size: 56px;font-family: Arial;color: #6a4760;margin-left: -355px"><p>Nanobody Sequence Search</p></div>
      </div>
      <div v-show="!isSearch" style="justify-content: center;display: flex;border-bottom: rgba(238,238,238,0.82) 1px solid;margin-top:33px;
      background-color:rgba(255,255,255,0.09);color: rgba(84,84,84,0.94);
      width: 58%;margin-left: 21%;font-size: 18px;line-height: 1.6;">
        <div style="display: flex;margin-top: 10px;">
          <div style="margin-left: 3px;">
            <button class="tagButton">Sequence</button>
            <input class="my-input" v-model="sequence" placeholder=" sequence" @keydown.enter="getSequenceData('seq')">
          </div>
          <div>
            <button class="my-button" @click="getSequenceData('seq')">Search</button>
          </div>
        </div>
      </div>
      <div v-show="!isSearch" style="justify-content: center;display: flex;border-bottom: rgba(238,238,238,0.82) 1px solid;margin-top:33px;
      background-color:rgba(255,255,255,0.09);color: rgba(84,84,84,0.94);
      width: 58%;margin-left: 21%;font-size: 18px;line-height: 1.6;">
        <div style="display: flex;margin-top: 10px">
          <div style="margin-left: 3px;">
            <button class="tagButton">CDR1</button>
            <input class="my-input" v-model="cdr1" placeholder=" cdr1" @keydown.enter="getSequenceData('cdr1')">
          </div>
          <div>
            <button class="my-button" @click="getSequenceData(' cdr1')">Search</button>
          </div>
        </div>
      </div>
      <div v-show="!isSearch" style="justify-content: center;display: flex;border-bottom: rgba(238,238,238,0.82) 1px solid;margin-top:33px;
      background-color:rgba(255,255,255,0.09);color: rgba(84,84,84,0.94);
      width: 58%;margin-left: 21%;font-size: 18px;line-height: 1.6;">
        <div style="display: flex;margin-top: 10px">
          <div style="margin-left: 3px;">
            <button class="tagButton">CDR2</button>
            <input class="my-input" v-model="cdr2" placeholder=" cdr2" @keydown.enter="getSequenceData('cdr2')">
          </div>
          <div>
            <button class="my-button" @click="getSequenceData('cdr2')">Search</button>
          </div>
        </div>

      </div>
      <div v-show="!isSearch" style="justify-content: center;display: flex;border-bottom: rgba(238,238,238,0.82) 1px solid;margin-top:33px;
      background-color:rgba(255,255,255,0.09);color: rgba(84,84,84,0.94);
      width: 58%;margin-left: 21%;font-size: 18px;line-height: 1.6;">
        <div style="display: flex;margin-top: 10px">
          <div style="margin-left: 3px;">
            <button class="tagButton">CDR3</button>
            <input class="my-input" v-model="cdr3" placeholder=" cdr3" @keydown.enter="getSequenceData('cdr3')">
          </div>
          <div>
            <button class="my-button" @click="getSequenceData('cdr3')">Search</button>
          </div>
        </div>

      </div>
      <div style="margin-left: 750px;margin-top: 45px;" v-show="!isSearch" >
        <span style="font-family: Arial;font-size: 17px;color: #626262"> * Search for the information of nanobody‘s sequence, include chain’s sequence,cdr1,cdr2,cdr3.</span>
      </div>
      <br>
      <div style="justify-content: center;margin-left: 0%;" v-show="isSearch">
        <div style="margin-left: 11%;font-weight: normal;color: #496686;font-family: 'Book Antiqua'">
          <el-button
              style="background-color: rgba(229,217,131,0.86); color: #003079; width: 151px;height: 32px;border:none;font-family: Arial;font-size: 16px;cursor: pointer;">
            Type&nbsp; :&nbsp;&nbsp;{{ type(this.searchType) }}
          </el-button>
          <el-button
              style="background-color: rgba(229,217,131,0.86); color: #003079; width: 122px;height: 32px;border:none;font-family: Arial;font-size: 16px;cursor: pointer;">
            Total&nbsp; :&nbsp;&nbsp;{{ total }}
          </el-button>&nbsp;&nbsp;
          <el-input @keydown.enter="getSequenceData(this.searchType)" v-model="sequence" v-show="this.searchType=='seq'"
                    style="width: 233px;margin-left: 12%;"></el-input>
          <el-input @keydown.enter="getSequenceData(this.searchType)" v-model="cdr1" v-show="this.searchType=='cdr1'"
                    style="width: 233px;margin-left: 12%;"></el-input>
          <el-input @keydown.enter="getSequenceData(this.searchType)" v-model="cdr2" v-show="this.searchType=='cdr2'"
                    style="width: 233px;margin-left: 12%;"></el-input>
          <el-input @keydown.enter="getSequenceData(this.searchType)" v-model="cdr3" v-show="this.searchType=='cdr3'"
                    style="width: 233px;margin-left: 12%;"></el-input>
          <el-button @click="getSequenceData(this.searchType)" style="margin-left:1%;background-color: #a7c1ff; color: #4f4f4f; width: 99px;height: 32px;border:none;
          font-family: Arial;
          font-size: 16px;cursor:pointer;">
            Search
          </el-button>
          <el-button @click="this.isSearch=false;"
                     style="margin-left:15%;background-color: #ffbbb4; color: #3f29ff; width: 131px;height: 32px;border:none;font-family: Arial;font-size: 16px;cursor:pointer;">
            Back
          </el-button>&nbsp;&nbsp;
          <el-button @click="downSeqText"
                     style="background-color: #bbe6ff; color: #005630; width: 151px;height: 32px;border:none;font-family: Arial;font-size: 16px;cursor:pointer;">
            Download selected
          </el-button>
        </div>
        <br>
        <el-pagination
            style="margin-left: 13%;margin-top: 10px;"
            pager-count="15"
            background
            :current-page="currentPage"
            :page-size="size"
            :page-sizes="[15,30,60,120,240]"
            @size-change="handleSizeChange"
            :total="total"
            layout="prev,  pager,  next, sizes,  jumper"
            @current-change="handleCurrentChange"
        ></el-pagination>
        <el-table
            ref="table"
            :data="dataList"
            highlight-current-row
            style="width: 85%;margin-left: 7%;"
            @selection-change="handleSelectionChange"
            :selectable="false"
            :select-on-indeterminate="false">
          <el-table-column type="selection" width="55"/>
          <el-table-column label="Sequence" prop="seq" width="auto" min-width="450">
            <template #default="scope">
              <div v-html="formatSequence('seq',scope.row.seq)">
              </div>
            </template>
          </el-table-column>
          <el-table-column label="CDR1" prop="cdr1" width="auto">
            <template #default="scope">
              <div v-html="formatSequence('cdr1',scope.row.cdr1)">
              </div>
            </template>
          </el-table-column>
          <el-table-column label=CDR2 prop="cdr2" width="auto">
            <template #default="scope">
              <div v-html="formatSequence('cdr2',scope.row.cdr2)">
              </div>
            </template>
          </el-table-column>
          <el-table-column label="CDR3" prop="cdr3" width="auto">
            <template #default="scope">
              <div v-html="formatSequence('cdr3',scope.row.cdr3)">
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" width="166">
            <template #header>
              <span>download sequence</span>
            </template>
            <template #default="scope">
              <el-button type="text" @click="downSeqText1(scope.row)">download</el-button>
            </template>
          </el-table-column>
        </el-table>
        <br>
        <el-pagination
            style="margin-left: 13%;margin-top: 10px;"
            pager-count="15"
            background
            :current-page="currentPage"
            :page-size="size"
            :page-sizes="[15,30,60,120,240]"
            @size-change="handleSizeChange"
            :total="total"
            layout="total, prev,  pager,  next, sizes,  jumper"
            @current-change="handleCurrentChange"
        ></el-pagination>
        <br><br><br>
      </div>
    </el-main><br><br><br><br><br><br><br><br><br><br><br><br>
    <el-footer>
      <Footer></Footer>
    </el-footer>
  </el-container>
</template>

<script>
import Header from '@/views/Index/Header.vue'
import {getRequest} from "@/utils/api";
import {ElMessage} from "element-plus";
import Footer from "@/views/Index/Footer.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Sequence",
  components: {Footer, Header},
  data() {
    return {
      size: 15,
      currentPage: 1,
      sequence: 'eg: GGGGLVQAGGS',
      cdr1: 'eg: GRTFYNYA',
      cdr2: 'eg: VSQSGEIT',
      cdr3: 'eg: NTWPMKAST',
      dataList: [],
      total: null,
      isSearch: false,
      maxSelection: 3,
      selectedRows: [],
      searchType: ''
    }
  },
  watch: {
    selectedRows(val) {
      // console.log(val); // 输出当前选中的行
    }
  },
  methods: {
    type(type) {
      return ((type == 'seq') ? 'Sequence' : ((type == 'cdr1') ? 'CDR1' : ((type == 'cdr2') ? 'CDR2' : 'CDR3')))
    },
    downSeqText1(data) {
      var text = '>>>\nSequence:\t' + data.seq + '\nCDR1:\t' + data.cdr1 + '\nCDR2:\t' + data.cdr2 + '\nCDR3:\t' + data.cdr3 + '\n<<<';
      this.downloadTextFile(text, 'seqs.txt');
    },
    downSeqText() {
      var text = this.mixSeq();
      this.downloadTextFile(text, 'seqs.txt');
    },
    downloadTextFile(text, filename) {
      // 创建一个 Blob 对象，并指定 MIME 类型为 text/plain
      const blob = new Blob([text], {type: 'text/plain'});
      // 创建一个 URL 对象，指向 Blob 对象
      const url = URL.createObjectURL(blob);
      // 创建一个 <a> 元素，并设置 download 属性和 href 属性为 URL 对象
      const a = document.createElement('a');
      a.download = filename;
      a.href = url;
      // 将 <a> 元素添加到文档中，并模拟点击它
      document.body.appendChild(a);
      a.click();
      // 删除 <a> 元素和 URL 对象
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
    mixSeq() {
      let text = '';
      let data = this.selectedRows;
      text += 'Row count:  ' + data.length.toString() + '\nSearch Type:  ' + this.type(this.searchType) + '\nKeyword:  ';
      switch (this.searchType) {
        case 'seq':
          text += this.sequence;
          break;
        case 'cdr1':
          text += this.cdr1;
          break;
        case 'cdr2':
          text += this.cdr2;
          break;
        case 'cdr3':
          text += this.cdr3;
          break;
      }
      text+='\n<<<';
      for (let i = 0; i < data.length; i++) {
        var seq = '\nSequence:\t' + data[i].seq + '\nCDR1:\t' + data[i].cdr1 + '\nCDR2:\t' + data[i].cdr2 + '\nCDR3:\t' + data[i].cdr3 + '\n<<<';
        text += seq;
      }
      this.selectedRows = '';
      return text;
      // console.log(text);
    },
    handleSelectionChange(rows) {
      if (rows.length > 240) {
        rows = rows.slice(0, 240);
        this.$refs.table.toggleRowSelection(rows[239], false);
        this.$message.warning('at most 240 entries');
      }
      this.selectedRows = rows;
    },
    formatSequence(type, cellValue) {
      return type == this.searchType ? this.formatWord(type, cellValue) : cellValue;
    },
    formatWord(type, cellValue) {
      let substring = '';
      switch (type) {
        case 'seq':
          substring = this.sequence.toUpperCase();
          break;
        case 'cdr1':
          substring = this.cdr1.toUpperCase();
          break;
        case 'cdr2':
          substring = this.cdr2.toUpperCase();
          break;
        case 'cdr3':
          substring = this.cdr3.toUpperCase();
          break;
      }
      const redText = '<span style="color:black;background: yellow;">' + substring + '</span>';
      const highlightedText = cellValue.replace(substring, redText);
      return highlightedText;
    },
    // 改变每页显示的数据条数时的回调
    handleSizeChange(newSize) {
      this.size = newSize;
      this.getSequenceData(this.searchType);
    },
    // 改变当前页码时的回调
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.getSequenceData(this.searchType);
    },
    getSequenceData(type) {
      if(type.at(0)==' '){
        type='cdr1';
      }
      let url = '/sequence/' + type + '?page=' + this.currentPage + '&&size=' + this.size + '&&' + type + '=';
      switch (type) {
        case 'seq':
          if (this.sequence == 'eg: GGGGLVQAGGS') {
            url += 'GGGGLVQAGGS';
            this.sequence = 'GGGGLVQAGGS';
          } else {
            url += this.sequence;
          }
          break;
        case 'cdr1':
          if (this.cdr1 == 'eg: GRTFYNYA') {
            url += 'GRTFYNYA';
            this.cdr1='GRTFYNYA';
          } else {
            url += this.cdr1;
          }
          break;
        case 'cdr2':
          if (this.cdr2 == 'eg: VSQSGEIT') {
            url += 'VSQSGEIT';
            this.cdr2='VSQSGEIT'
          } else {
            url += this.cdr2;
          }
          break;
        case 'cdr3':
          if (this.cdr3 == 'eg: NTWPMKAST') {
            url += 'NTWPMKAST';
            this.cdr3 = 'NTWPMKAST';
          } else {
            url += this.cdr3;
          }
          break;
      }
      // console.log(url)
      getRequest(url).then(resp => {
        if (resp) {
          // console.log(resp)
          this.dataList = [];
          this.total = null;
          this.dataList = resp.obj.data;
          this.total = resp.obj.total;
          this.isSearch = true;
          this.searchType = type;
          ElMessage.success("success!")
        } else {
          this.searchType = '';
        }
      })
    },
  }
}
</script>

<style scoped>

/deep/ .el-table thead tr > th {
  color: #000764;
  /*background-color: #5e6e96;*/
  background-color: #f3f3f3;
  /*color: #fbbc07;*/
}

/* 更改表格每行背景颜色*/
/deep/ .el-table tbody tr > td {
  color: #2d4259;
  background-color: #f2f2f2;
}

/* 设置鼠标经过时背景颜色*/
/deep/ .el-table tbody tr:hover > td {
  background-color: #FFFFF0 !important;
}

.table-own thead {
  background-color: #f2f2f2;
}

.table-own th,
.table-own td {
  padding: 6px;
  text-align: center;
  /*border: 1px solid #ddd;*/
}

.table-own th.sn,
.table-own td.sn {
  text-align: center;
  /*width: 88px;*/
}

.table-own th.pdb-id,
.table-own td.pdb-id {
  text-align: center;
  /*width: 188px;*/
}

.table-own th.description,
.table-own td.description {
  text-align: center;
  width: auto;
}

.table-own th.pdb-file,
.table-own td.pdb-file {
  text-align: left;
  /*width: 158px;*/
}

.table-own tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table-own tbody tr:hover {
  background-color: #ddd;
}

.link-style {
  text-decoration: none;
  color: #007bff;
}

:deep(.el-pagination.is-background .el-pager li:not(.is-disabled).is-active) {
  background-color: rgb(127, 134, 217) !important;
}

.link-style {
  color: rgba(0, 0, 0, 0.93);
  text-decoration: none; /* 去掉下划线 */
  position: relative; /* 让子元素相对于父元素进行定位 */
}

.link-style::before {
  content: ""; /* 添加一个伪元素作为动画效果的基本元素 */
  position: absolute;
  bottom: -3px; /* 将伪元素定位在链接文本底部 */
  width: 0%;
  height: 2px;
  /*background-color: rgba(255, 0, 0, 0.91);*/
  background-color: red;
  transition: all 0.25s ease-in-out; /* 添加所有CSS属性过渡效果 */
}

.link-style:hover::before {
  width: 180%; /* 鼠标悬停时将伪元素宽度调整到100% */
  margin-left: -39%;
}


.uniqueProteinChains {
  width: auto;
}

.organism {
  width: auto;
}

table {
  border-collapse: collapse;
  /*box-shadow: 2px 2px 12px rgba(189, 170, 170, 0.84);*/
}

td {
  width: 233px;
  /*border: 3px solid #7e7e7e;*/
  /*padding: 5px;*/
}

.niam {
  /*justify-content: center;*/
  background-color: #f2f2f2;
  position: absolute;
  top: 6%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.tagButton {
  width: 98px;
  border: #496686 2px solid;
  /*border-radius: 5px;*/
  text-align: left;
  height: 48px;
  background-color: #dadada;
  border-right: none;
  font-size: 18px;
  color: #333;
}

/* 输入框样式 */
.my-input {
  width: 666px;
  border: #496686 2px solid;
  /*border-radius: 5px;*/
  background-color: #f2f2f2;
  height: 42px;
  font-size: 18px;
  color: #333;
  /*box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);*/
  transition: all 0.3s ease;
}

.my-input:focus {
  /*width: 666px;*/
  outline: none;
  border-color: #4c5fa2;
  box-shadow: 0px 2px 10px rgba(126, 156, 255, 0.5);
}

/* 按钮样式 */
.my-button {
  margin-left: 15px;
  height: 46px;
  border: 1px #496686 solid;
  width: 72px;
  background-color: #496686;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.my-button:hover {
  background-color: #6772e5;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.my-button:focus {
  outline: none;
  box-shadow: 0px 2px 10px rgba(126, 156, 255, 0.5);
}


</style>
