<template>
  <div class="header">
    <div class="container">
      <div class="logo">
        <p v-show="!showMenu" @click="toIndex" style="position:relative;cursor: pointer;font-size: 21px;color: white;font-weight: bold;width: auto;">NanoLAS DB</p>
        <h1 v-show="showMenu">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h1>
      </div>
      <nav class="navigation">
        <ul v-if="!isSmallScreen">
          <li><a href="?#/index">Home</a></li>
          <li><a href="?#/search">Nanobody</a></li>
          <li><a href="?#/bindSearch">Ligand-receptor</a></li>
          <li><a href="?#/siteSearch">Active Residue</a></li>
          <li><a href="?#/sarscov2">SARS-Cov2</a></li>
          <li><a href="?#/sequence">Sequence</a></li>


          <li><a href="?#/contact">About</a></li>
          <li><a href="?#/download">Download</a></li>

          <el-dropdown class="dropdown-menu">
            <li><a href="?#/home">
                  User's Page
            </a></li>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item class="dropdown-item" style="justify-content: center">
                  <li><a href="?#/history/search">Search Record</a></li>
                </el-dropdown-item>
                <el-dropdown-item class="dropdown-item" style="justify-content: center">
                  <li><a href="?#/user/info">Personal Info</a></li>
                </el-dropdown-item>
                <el-dropdown-item class="dropdown-item" style="justify-content: center">
                  <li><a href="?#/wait/list">Review List</a></li>
                </el-dropdown-item>
                <el-dropdown-item class="dropdown-item" style="justify-content: center">
                  <li><a href="?#/upload/page">Upload</a></li>
                </el-dropdown-item>
                <el-dropdown-item style="justify-content: center;color: #ff6565" @click="logout">
                  <li><a>logout</a></li>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

        </ul>
        <div v-else>
          <button @click="toggleMenu" class="menu-toggle">
            <span></span>
            <span></span>
            <span></span>
          </button>
          <ul v-if="showMenu" class="mobile-menu">
            <li><a href="?#/index">Home</a></li>

            <li style="margin-left: -10px"><a href="#/contact">About</a></li>
            <el-dropdown style="margin-left: -28px" class="dropdown-menu">
              <li><a href="#/home">
                <img src="@/assets/user.png" width="36" height="36">
              </a></li>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item class="dropdown-item" style="justify-content: center">
                    <li><a href="#/history/search">Search Record</a></li>
                  </el-dropdown-item>
                  <el-dropdown-item class="dropdown-item" style="justify-content: center">
                    <li><a href="#/user/info">Personal Info</a></li>
                  </el-dropdown-item>
                  <el-dropdown-item class="dropdown-item" style="justify-content: center">
                    <li><a href="#/wait/list">Review List</a></li>
                  </el-dropdown-item>
                  <el-dropdown-item class="dropdown-item" style="justify-content: center">
                    <li><a href="#/upload/page">Upload</a></li>
                  </el-dropdown-item>
                  <el-dropdown-item style="justify-content: center;color: #ff6565" @click="logout">
                    <li><a>logout</a></li>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </ul>
        </div>

      </nav>
    </div>
  </div>
</template>

<script>
import {getRequest} from "@/utils/api";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  data() {
    return {
      isSmallScreen: false, // 默认为大屏幕
      showMenu: false,
      showDropDownMenu: false,
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize();
  },
  methods: {
    handleResize() {
      this.isSmallScreen = window.innerWidth < 900;
      this.showMenu = window.innerWidth < 900;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    toIndex() {
      window.location.href = '#/index';
    },
    logout() {
      getRequest('/logout');
      window.sessionStorage.removeItem("user");
      this.$router.push({name: "Login"})
      this.$store.commit('initRoutes', [])
    }
  }
}
</script>

<style>

.dropdown-item a {
  font-size: 15px;
  color: #010407;
  text-decoration: none;
}


.menu-toggle {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  z-index: 1;
  position: relative;
}

.menu-toggle span {
  display: block;
  height: 2px;
  width: 100%;
  position: relative;
  background: #333;
  transition: all 0.2s ease-in-out;
}

.mobile-menu {
  list-style: none;
  margin-right: 2%;
  padding: 20px;
  /*background-color: rgba(94, 84, 84, 1);*/
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  /*box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);*/
  transform: translateY(-100%);
  transition: all 0.3s ease-in-out;
}

.mobile-menu li {
  margin-bottom: 10px;
}

.mobile-menu a {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  padding: 8px;
  /*border-radius: 5px;*/
  display: block;
}

.mobile-menu a:hover {
  color: #ffffff;
  background-color: #5B688EFF;
}

.show-menu .menu-toggle span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.show-menu .menu-toggle span:nth-child(2) {
  opacity: 0;
}

.show-menu .menu-toggle span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.show-menu .mobile-menu {
  transform: translateY(0);
}

/**/
.header {
  height: 5vh;
  padding: 10px 0;
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .logo img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
}

.header .logo h1 {
  color: white;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.logo {
  margin-top: -8px;
  margin-left: 4%;
}

.header {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  padding: 10px 0;
  transition: all 0.3s ease-in-out;
  background-color: #57668f;
  /*background-color: #cec955;*/
  /*background-color: rgba(108, 84, 100, 1);*/
}

.navigation {
  margin-left: 3px;
  margin-right: 12%;
}

.header .navigation ul li {
  margin-right: 15px;
  margin-left: 15px;
}

.header .navigation ul li a {
  font-size: 16px;
  font-weight: normal;
  color: #ffffff;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  padding: 18px 8px;
  margin-top: -22px;
  height: 100%;
  display: flex;
  align-items: center;
}

.header .navigation ul li a {
  position: relative;
}

.header .navigation ul li a::before {
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  bottom: -5px;
  left: -10px;
  right: -10px;
}


.header .navigation ul li a:hover {
  color: #000000;
  background-color: #ffffff;
}


.header .navigation ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.header .navigation ul li:last-child {
  margin-right: 0;
}


</style>
