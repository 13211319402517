import {createRouter, createWebHashHistory} from 'vue-router';
import Home from '@/views/PersonCenter.vue'
import List from '@/views/List.vue'
import Show from "@/views/Show.vue";
import Compare from "@/views/Compare.vue";
import UserInfo from "@/views/PersonCenter/UserInfo.vue";
import UpLoadHistory from "@/views/PersonCenter/UpLoadHistory.vue";
import DownLoadHistory from "@/views/PersonCenter/DownLoadHistory.vue";
import AdminExamine from "@/views/PersonCenter/AdminExamine.vue";
import AdminUser from "@/views/PersonCenter/AdminUser.vue";
import AdminData from "@/views/PersonCenter/AdminData.vue";
import SearchHistory from "@/views/PersonCenter/SearchHistory.vue";
import waitList from "@/views/PersonCenter/waitList.vue";
import Index from "@/views/Index.vue";
import Login from "@/views/Login.vue";
import UploadPage from "@/views/PersonCenter/UploadPage.vue";
import About from "@/views/About.vue";
import Contact from "@/views/Contact.vue";
import GetBack from "@/views/GetBack.vue";
import CovSearch from "@/views/Search/CovSearch.vue";
import Search from "@/views/Search/Search.vue";
import SiteSearch from "@/views/Search/SiteSearch.vue";
import BindSearch from "@/views/Search/BindSearch.vue";
import viewTest from "@/views/viewTest.vue";
import Sequence from "@/views/Sequence.vue";
import SarsCov2 from "@/views/Search/SarsCov2.vue";
import Download from "@/views/Download.vue";


const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/sequence',
            name: 'Sequence',
            component: Sequence
        },
        {
            path: '/viewtest',
            name: 'viewTest',
            component: viewTest
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            hidden: true
        }, {
            path: '/home',
            name: 'Home',
            component: Home,
            hidden: true
        }, {
            path: '/list',
            name: 'List',
            component: List,
            hidden: true,
        },
        {
            path: '/home',
            name: 'router-home',
            component: Home,
            children: [
                {
                    path: '/user/info',
                    name: 'User Info',
                    component: UserInfo
                }, {
                    path: '/history/search/',
                    name: 'Search Record',
                    component: SearchHistory
                }, {
                    path: '/history/upload',
                    name: 'Upload Record',
                    component: UpLoadHistory
                }, {
                    path: '/history/download',
                    name: 'Download Record',
                    component: DownLoadHistory
                }, {
                    path: '/admin/examine',
                    name: 'AdminExamine',
                    component: AdminExamine
                }, {
                    path: '/admin/user',
                    name: 'AdminUser',
                    component: AdminUser
                }, {
                    path: '/admin/data',
                    name: 'AdminData',
                    component: AdminData
                }, {
                    path: '/wait/list',
                    name: "Review List",
                    component: waitList
                }, {
                    path: '/upload/page',
                    name: 'Upload',
                    component: UploadPage
                }
            ]
        }, {
            path: '/show',
            name: 'PdbView',
            component: Show
        }, {
            path: '/compare',
            name: 'Compare',
            component: Compare
        }, {
            path: '/index',
            name: 'Index',
            component: Index
        }, {
            path: '/',
            name: '/',
            component: Index
        }, {
            path: '/about',
            name: 'About',
            component: About
        }, {
            path: '/contact',
            name: 'Contact',
            component: Contact
        }, {
            path: '/back',
            name: 'GetBack',
            component: GetBack
        }, {
            path: '/cov',
            name: 'CovSearch',
            component: CovSearch
        }, {
            path: '/search',
            name: 'Search',
            component: Search
        }, {
            path: '/siteSearch',
            name: 'SiteSearch',
            component: SiteSearch
        }, {
            path: '/bindSearch',
            name: 'BindSearch',
            component: BindSearch
        }, {
            path: '/sarscov2',
            name: 'SarsCov2',
            component: SarsCov2
        }, {
            path: '/activesitesearch',
            name: SiteSearch,
            component: SiteSearch
        }, {
            path: '/download',
            name: Download,
            component: Download
        }
    ]
})

export default router
