<template>
  <div>
    <div style="margin-bottom: 20px;display: flex">
      <el-button color="green" style="height: 32px" @click="getUserList">Search</el-button>
      <el-input v-model="keyword" @keydown.enter="getUserList" style="width: 233px;margin-left: 18px;height: 32px;"
                placeholder="search by username"></el-input>
      <el-tag style="height: 32px;color: black;margin-left: 35px;" type="info">Awaiting review</el-tag>
      <el-radio-group v-model="isWaitForReview" @change="getUserList">
        <el-radio label="true" border><p style="font-size: 12px">True</p></el-radio>
        <el-radio label="false" border style="margin-left: -32px;"><p style="font-size: 12px">False</p></el-radio>
      </el-radio-group>
    </div>
    <el-table :data="userList" style="width: 100%;font-size: 13px;" border>
      <el-table-column label="SN" align="center" width="45">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="username" label="Username" width="133"/>
      <el-table-column prop="name" label="Name" width="233"/>
      <el-table-column prop="establishment" label="Establishment" width="188"/>
      <el-table-column prop="occupation" label="Occupation" width="111"/>
      <el-table-column prop="email" label="Email" width="233"/>
      <el-table-column label="Actions" width="180" align="center">
        <template v-slot="scope">
          <el-tooltip
              effect="dark"
              content="Level-up agree"
              placement="top-start"
          >
            <el-icon size="large" @click="levelUp(scope.row.uid)" style="cursor: pointer;"><Select/></el-icon>
          </el-tooltip>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-tooltip
              effect="dark"
              content="Level-up disagree"
              placement="top-start"
          >
            <el-icon size="large" @click="levelUpDis(scope.row.uid)" style="cursor: pointer;">
              <CloseBold/>
            </el-icon>
          </el-tooltip>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-tooltip
              effect="dark"
              content="Delete user"
              placement="top-start"
          >
              <el-icon size="large" @click="deleteUser(scope.row.uid)" style="cursor: pointer;">
                <Delete/>
              </el-icon>
          </el-tooltip>

        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top: 2%;margin-left: 1%;">
      <el-pagination
          :current-page="currentPage"
          :page-sizes="total"
          :page-size="size"
          @size-change="handleSizeChange"
          layout="total, sizes,  prev,  pager,  next,  jumper"
          :total="total"
          @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import {getRequest} from "@/utils/api";

export default {
  name: "AdminUser",
  data() {
    return {
      userList: [],
      page: 1,
      currentPage: 1,
      total: '',
      size: 10,
      dialogVisible: false,
      canDownload: false,
      keyword: '',
      isWaitForReview: false
    }
  },
  mounted() {
    this.getUserList();
  },
  methods: {
    deleteUser(uid) {
      if (confirm('Are you sure you want to delete this user?')) {
        getRequest('/admin/user/del/' + uid).then(resp => {
          if (resp) {
            return;
          }
        }).finally(() => {
          this.getUserList();
        })
      }
    },

    levelUpDis(uid) {
      getRequest('/admin/user/disLevelUp/' + uid).then(resp => {
        if (resp) {
          return;
        }
      }).finally(() => {
        this.getUserList();
      })
    },
    levelUp(uid) {
      // console.log(uid)
      getRequest('/admin/user/levelUp/' + uid).then(resp => {
        if (resp) {
          return;
        }
      }).finally(() => {
        this.getUserList();
      })
    },
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.getUserList();
    },
    getUserList() {
      getRequest('/admin/user/list?page=' + this.currentPage + '&&size=' + this.size + '&&keyword=' + this.keyword + '&&isWaitForReview=' + this.isWaitForReview).then(resp => {
        if (resp) {
          this.userList = resp.data;
          this.total = resp.total;
          // console.log(this.userList)
        }
      }).finally(() => {
        this.keyword = '';
      })
    },
    handleSizeChange(newSize) {
      this.size = newSize;
      this.getUserList();
    },

  }
}
</script>

<style scoped>

</style>
