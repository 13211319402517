<template>
  <el-container style="display: flex; flex-direction: column; height: 100%;">
    <el-header>
      <Header></Header>
    </el-header>
    <el-main>
      <div class="container">
        <div id="container-01" class="mol-container-01" ref="molContainer01">
        </div>
        <div id="container-02" class="mol-container-02" ref="molContainer02">
        </div>
      </div>
      <table class="comparison-table">
        <thead>
        <tr>
          <th>Pid</th>
          <td>
            <el-link style="color: red;font-size: 17px" @click="goToPath(this.pid1)">{{ this.pid1 }}</el-link>
          </td>
          <td>
            <el-link style="color: red;font-size: 17px" @click="goToPath(this.pid2)">{{ this.pid2 }}</el-link>
          </td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th>Description</th>
          <td>{{ this.pdb1Desc.description }}</td>
          <td>{{ this.pdb2Desc.description }}</td>
        </tr>
        <tr>
          <th>Total Structure Weight</th>
          <td>{{ this.pdb1Desc.totalStructureWeight }}</td>
          <td>{{ this.pdb2Desc.totalStructureWeight }}</td>
        </tr>
        <tr>
          <th>AtomCount</th>
          <td>{{ this.pdb1Desc.atomCount }}</td>
          <td>{{ this.pdb2Desc.atomCount }}</td>
        </tr>
        <tr>
          <th>Modelled Residue Count</th>
          <td>{{ this.pdb1Desc.modelledResidueCount }}</td>
          <td>{{ this.pdb2Desc.modelledResidueCount }}</td>
        </tr>
        <tr>
          <th>Deposited Residue Count</th>
          <td>{{ this.pdb1Desc.depositedResidueCount }}</td>
          <td>{{ this.pdb2Desc.depositedResidueCount }}</td>
        </tr>
        <tr>
          <th>Unique Protein Chains</th>
          <td>{{ this.pdb1Desc.uniqueProteinChains }}</td>
          <td>{{ this.pdb2Desc.uniqueProteinChains }}</td>
        </tr>
        <tr>
          <th>Deposition Author</th>
          <td>{{ this.pdb1Desc.depositionAuthor }}</td>
          <td>{{ this.pdb2Desc.depositionAuthor }}</td>
        </tr>
        <tr>
          <th>Release Date</th>
          <td>{{ this.pdb1Desc.releaseDate }}</td>
          <td>{{ this.pdb2Desc.releaseDate }}</td>
        </tr>
        <tr>
          <th>Detail</th>
          <td><el-link style="font-size: 17px" @click="goToPath(this.pid1)"> more </el-link></td>
          <td><el-link style="font-size: 17px" @click="goToPath(this.pid2)"> more </el-link></td>
        </tr>
        </tbody>
      </table>

    </el-main>
  </el-container>
</template>

<script>
import * as $3Dmol from "3dmol"
import {getRequest} from "@/utils/api";
import decryptionConfig from "@/utils/decryption";
import Header from "@/views/Index/Header.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Compare",
  components: {Header},
  data() {
    return {
      pid1: '',
      pid2: '',
      pdb1Desc: {},
      pdb2Desc: {},
      twoProtein: [],
      viewer1: null,
      viewer2: null
    }
  },
  created() {
    //获取需要比较的两个pid
    this.pid1 = this.$route.query.pid1;
    this.pid2 = this.$route.query.pid2;
    this.viewer1 = null;
    this.viewer2 = null;
  },
  mounted() {
    this.viewer1 = $3Dmol.createViewer(this.$refs.molContainer01, {});
    this.viewer2 = $3Dmol.createViewer(this.$refs.molContainer02, {});
    //获取pbd文件创建视图
    this.getTwoProtein();
  },
  methods: {
    getTwoProtein() {
      getRequest("/protein/compare/" + this.pid1 + "/" + this.pid2).then(resp => {
        this.twoProtein = resp.obj;
        this.pdb1Desc = this.twoProtein[0];
        this.pdb2Desc = this.twoProtein[1];
      }).finally(() => {
        this.viewer1 = this.createViewer(this.pdb1Desc, this.viewer1);
        this.viewer2 = this.createViewer(this.pdb2Desc, this.viewer2);
      })
    },
    goToIndex() {
      this.$router.push("/index");
    },
    goToList() {
      this.$router.push("/list");
    },
    goToPath(pid) {
      this.$router.push("/show?pid=" + pid);
    },
    setCartoonStyle(viewer) {
      viewer.setStyle({}, {cartoon: {colorscheme: 'greenCarbon'}, sphere: {radius: 0.2}});
      viewer.render();
    },
    createViewer(pdbDesc, viewer) {
      viewer.addModel(pdbDesc.pdbStr, 'pdb');
      this.setCartoonStyle(viewer);
      viewer.zoomTo();
      viewer.render();
      viewer.zoom(1.2, 1000);
      return viewer;
    },
  }
}
</script>

<style scoped>

.comparison-table {
  font-family: Arial;
  border-collapse: collapse;
  border: 2px solid #777777;
  width: 66%;
  margin-left: 17%;
}

.comparison-table th,
.comparison-table td {
  padding: 6px;
  border: 1px solid #345a82;
  text-align: left;
  width: 300px;
}

.comparison-table th {
  background-color: rgba(52, 90, 130, 0.86);
  width: 178px;
  color: white;
}

@keyframes rainbow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 60%;
  margin-left: 20%;
  margin-top: 1%;
}

.mol-container-01,
.mol-container-02 {
  width: 46%;
  height: 300px;
  position: relative;
  border-radius: 2px;
  border: 1px solid #ccc;
  box-shadow: 0 3px 3px rgba(19, 18, 18, 0.5);
  margin-bottom: 20px;
}

</style>
