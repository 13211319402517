<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'About',
}
</script>
<template>
  <div class="about"><h2>Welcome to our lab!</h2>
    <p>We are a team dedicated to researching nanoscale proteins. Our goal is to advance protein research and drug
      design by developing new computational tools and technologies. Our research covers multiple fields, including
      machine learning, molecular simulation, and computer-aided drug design.We named our database NanoLAS, it could be interpreted as Nanobody Library and Archive System. It could also be interpreted as a database related with Nanobody, Ligand-receptor, Active residue, SARS-COV2 and nanobody related sequences.</p>
    <p>We are currently focused on the following four research directions:</p>
    <ol>
      <li><strong>Development of machine learning-based protein structure prediction algorithms and software:</strong>
        We are developing a new machine learning algorithm that can predict the three-dimensional structure of proteins,
        and we are developing corresponding software to achieve this goal. We believe that this technology will help
        solve many challenges in biology and drug design.
      </li>
      <li><strong>Evaluation of molecular folding and docking algorithms:</strong> We are evaluating the performance of
        molecular folding and docking algorithms and using these techniques to study the structure and function of
        proteins. We believe that these techniques have broad applications in drug design and other fields.
      </li>
      <li><strong>Computer-aided screening for antibodies specific to certain pathogens (viruses, etc.):</strong> We are
        using computer-aided screening techniques to find antibodies specific to certain pathogens, and we are applying
        these techniques to drug design and other fields.
      </li>
      <li><strong>Construction of relevant databases:</strong> We are building relevant databases to store and share
        data related to protein structure, function, and interaction. We believe that these databases will provide
        researchers with better resources and support.
      </li>
    </ol>
    <p>Our lab is composed of a team of professors, researchers, postdoctoral fellows, and graduate students with rich
      experience and knowledge in different research directions. Our research projects are supported by multiple
      institutions and foundations, and we have established extensive collaborations with other labs and
      institutions.</p>
    <p>If you are interested in our research, we welcome you to contact us. We look forward to sharing our research
      results with you and providing you with more information about our research directions.</p></div>
</template>
<style>
.about {
  margin: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.5;
}

.about h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.about p {
  font-size: 18px;
  margin-bottom: 10px;
}

.about ol {
  margin-left: 20px;
  font-size: 18px;
  margin-bottom: 10px;
}

.about li {
  font-size: 18px;
  margin-bottom: 5px;
}

.about strong {
  font-weight: bold;
}

.about a {
  color: #007bff;
  text-decoration: none;
}

.about a:hover {
  text-decoration: underline;
}
</style>
