import {createApp, watch} from 'vue'
import App from './App.vue'
// 引入 router
import router from "./router";
//引入 element-ui
import 'element-plus/dist/index.css'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

//引入请求工具类 api
import axios from "axios"
//引入vuex
import store from "@/store"
import {initMenu} from "@/utils/menus";


// import 'animate.css/animate.min.css'
// import animate from 'animate.css'
//
// import './assets/css/style.css'

//全局导航守卫
router.beforeEach((to, from, next) => {
    if (to.path === '/login' ||
        to.path === '/list' ||
        to.path === '/show' ||
        to.path === '/compare' ||
        to.path === '/index' ||
        to.path === '/contact' ||
        to.path === '/' ||
        to.path === '/about' ||
        to.path === '/back' ||
        to.path === '/cov' ||
        to.path === '/search' ||
        to.path === '/siteSearch' ||
        to.path === '/bindSearch' ||
        to.path === '/viewtest' ||
        to.path === '/sequence' ||
        to.path === '/sarscov2' ||
        to.path === '/activesitesearch'||
        to.path === '/download') {
        next();
    } else {
        if (window.sessionStorage.getItem("user")) {
            initMenu(router, store);
            next();
        } else {
            next('/login?redirect=' + to.path);
        }
    }
})

const app = createApp(App)
app.config.globalProperties.$http = axios
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(router)
app.use(ElementPlus)
app.use(store)

// app.use(animate)

app.mount('#app')

