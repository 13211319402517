<template>
  <div style="margin: 0 auto;justify-content: center;width: 100vh;">
    <Header></Header>
    <div class="full-screen-loading" v-show="showFullScreenLoading">
      <div class="loading-spinner"></div>
    </div>
    <h1 style="margin-top: 23%">Create A Random Password</h1>
    <div style="margin-left: 3%;margin-top: 8%;">
      &nbsp;Email&nbsp;&nbsp;&nbsp;
      <el-input style="width: 220px;" v-model="email" @keydown.enter="CNRP"></el-input>
      <el-button @click="CNRP" color="#5264AE" style="border-radius: 6px;margin-left: 39px;">Submit</el-button>
    </div>
    <div style="margin-left: 0%;">
      <p style="font-family: 'Arial';font-size: 16px;margin-top: 8%;color: darkblue;">&nbsp;Enter your email,&nbsp;&nbsp;and a new password will be sent
        to your email.</p>
    </div>
  </div>
</template>

<script>
import {getRequest} from "@/utils/api";
import {ElMessage} from "element-plus";
import Header from "@/views/Index/Header.vue";

export default {
  name: "GetBack",
  components: {Header},
  data() {
    return {
      email: '',
      showFullScreenLoading: false
    }
  },
  methods: {
    CNRP() {
      if (this.email == null || this.email == '') {
        ElMessage.error('Empty!');
        return;
      }
      let flag = false;
      for (let i = 0; i < this.email.length; i++) {
        if (this.email.charAt(i) == '@') {
          flag = true;
          break;
        }
      }
      if (flag) {
        this.showFullScreenLoading = true;
        getRequest('/getback?email=' + this.email).finally(()=>{
          this.showFullScreenLoading=false;
        })
      }else{
        ElMessage.error('Email format error!');
      }
    }
  }
}
</script>

<style scoped>

.full-screen-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* 使 loading 在最上层 */
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>