<template>
  <div>
    <el-table :data="examineList" style="width: 100%;font-size: 13px;" stripe>
      <el-table-column type="expand" width="133">
        <template v-slot="scope">
          <el-card style="width: 66%;margin-left: 8%;"  shadow="hover">
            <el-descriptions
                        :column="1"
                        size="default"
                        border
                    >
                      <el-descriptions-item label="Pid">
                        {{ scope.row.pid }}
                      </el-descriptions-item>
                      <el-descriptions-item label="Description">
                        {{ scope.row.description }}
                      </el-descriptions-item>
                      <el-descriptions-item label="Release Date">
                        {{ scope.row.releaseDate }}
                      </el-descriptions-item>
                      <el-descriptions-item label="Deposition Author">
                        {{ scope.row.depositionAuthor }}
                      </el-descriptions-item>
                      <el-descriptions-item label="Total Structure Weight">
                        {{ scope.row.totalStructureWeight }}
                      </el-descriptions-item>
                      <el-descriptions-item label="Atom Count">
                        {{ scope.row.atomCount }}
                      </el-descriptions-item>
                      <el-descriptions-item label="Modelled Residue Count">
                        {{ scope.row.modelledResidueCount }}
                      </el-descriptions-item>
                      <el-descriptions-item label="Deposited Residue Count">
                        {{ scope.row.depositedResidueCount }}
                      </el-descriptions-item>
                      <el-descriptions-item label="Unique Protein Chains">
                        {{ scope.row.uniqueProteinChains }}
                      </el-descriptions-item>
                      <el-descriptions-item label="Pdb File">
                        <el-button type="default" @click="saveAsPDBFile(scope.row.pid,scope.row.pdbStr)">download pdb file
                        </el-button>
                      </el-descriptions-item>
                    </el-descriptions>
          </el-card>
        </template>
      </el-table-column>
      <el-table-column label="SN" width="188" align="left">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="pid" label="PID" align="left" width="188"/>
      <el-table-column label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ACTIONS" width="488" align="left">
        <template v-slot="scope">
          <el-button type="text" style="width: 66px;" @click="pass(scope.row.id)">pass</el-button>
          <el-button type="text" style="width: 66px;" @click="nopass(scope.row.id)">nopass</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 2%;margin-left: 1%;">
      <el-pagination
          :current-page="currentPage"
          :page-sizes="total"
          :page-size="size"
          @size-change="handleSizeChange"
          layout="total, sizes,  prev,  pager,  next,  jumper"
          :total="total"
          @current-change="handleCurrentChange"
      />
    </div>

  </div>
</template>

<script>
import {getRequest} from "@/utils/api";

export default {
  name: "AdminExamine",
  data() {
    return {
      examineList: [],
      currentPage: 1,
      total: '',
      size: 10,
      dialogVisible: false
    }
  },
  mounted() {
    this.getExamineList();
  },
  methods: {
    handleSizeChange(newSize) {
      this.size = newSize;
      this.getExamineList();
    },
    pass(id) {
      getRequest('/admin/examine/' + id).then(resp => {
        if (resp) {
          this.getExamineList();
        }
      })
    },
    nopass(id) {
      const reason = prompt("Please enter a reason for rejecting the request.");
      if (reason !== null && reason !== "") { // Check if reason is not empty
        getRequest('/admin/examine/err?id=' + id + '&&reason=' + reason).then(resp => {
          if (resp) {
            this.getExamineList();
          }
        })
      }
    },
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.getExamineList();
    },
    getExamineList() {
      getRequest('/admin/examine/list?page=' + this.currentPage + "&&size=" + this.size).then(resp => {
        if (resp) {
          this.examineList = resp.data;
          this.total = resp.total;
        }
      })
    },
    saveAsPDBFile(pid, str) {
      const blob = new Blob([str], {type: 'chemical/x-pdb'});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = pid + '.pdb';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  }
}
</script>

<style scoped>

</style>