<template>
  <el-container style="display: flex; flex-direction: column; height: 100%;">
    <div class="full-screen-loading" v-show="showFullScreenLoading">
      <div class="loading-spinner"></div>
    </div>
    <el-header>
      <Header></Header>
    </el-header>
    <el-main class="niam">
      <div style="margin-left: 13%;margin-top: 6%">
        <img src="@/assets/logo1.png" width="250" height="377">
        <p style="text-align: center;font-size: 30px;font-weight: bold">NanoLAS</p>
      </div>
      <LoginForm :is-show-login="isShowLogin" @toggle-login="onToggleRegister" @toggle-loading="toggleLoading"></LoginForm>

      <RegisterForm :is-show-register="!isShowLogin" @toggle-register="onToggleRegister"
                    @toggle-loading="toggleLoading"></RegisterForm>

    </el-main>
  </el-container>
</template>

<script>
import Header from "@/views/Index/Header.vue";
import LoginForm from "@/views/Login/LoginForm.vue";
import RegisterForm from "@/views/Login/RegisterForm.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",
  // eslint-disable-next-line vue/no-unused-components
  components: {RegisterForm, LoginForm, Header},
  data() {
    return {
      isShowLogin: true,
      showFullScreenLoading: false
    }
  },
  methods: {
    onToggleRegister() {
      this.isShowLogin = !this.isShowLogin;
    },
    toggleLoading(value) {
      this.showFullScreenLoading = value;
    }
  }
}
</script>

<style scoped>

.niam {
  background-color: #f8f9fd;
  position: absolute;
  top: 9%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
}
.full-screen-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* 使 loading 在最上层 */
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
