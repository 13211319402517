<template>
  <div>
    <el-descriptions
        class="margin-top"
        :column="1"
        size="default"
        border
    >
      <el-descriptions-item>
        <template #label>
          <div class="cell-item">
            <el-icon :style="iconStyle">
              <user/>
            </el-icon>
            Username
          </div>
        </template>
        {{ this.user.username }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div class="cell-item">
            <el-icon>
              <Avatar/>
            </el-icon>
            Name
          </div>
        </template>
        {{ this.user.name }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div class="cell-item">
            <el-icon>
              <OfficeBuilding/>
            </el-icon>
            Establishment
          </div>
        </template>
        {{ this.user.establishment }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div class="cell-item">
            <el-icon :style="iconStyle">
              <tickets/>
            </el-icon>
            Occupation
          </div>
        </template>
        {{ this.user.occupation }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div class="cell-item">
            <el-icon>
              <Promotion/>
            </el-icon>
            Email
          </div>
        </template>
        {{ this.user.email }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div class="cell-item">
            <el-icon>
              <DocumentCopy/>
            </el-icon>
            Download permissions
          </div>
        </template>
        <el-tag type="success" v-show="this.canDownload">yes</el-tag>
        <el-tag type="danger" v-show="!this.canDownload">no</el-tag>
      </el-descriptions-item>

    </el-descriptions>
    <div style="margin-top: 2%;">
      <button @click="dialogVisible = true" class="modifyButton">Modify Person Info</button>
      <button @click="dialogVisible1 = true" class="modifyButton">Modify Password</button>
<!--      <el-button :disabled="isDisabled" class="tooltip" style="background-color: green;color: white;height: 35px;"-->
<!--                 @click="reqDownPer">Apply for Download Access-->
<!--        <span class="tooltiptext" v-show="isDisabled">-->
<!--          <p v-show="canDownload">Already have download permission</p>-->
<!--          <p v-show="!canDownload">Please confirm whether your personal information has been completed before proceeding.</p>-->
<!--        </span>-->
<!--      </el-button>-->
    </div>
    <el-dialog title="Modify Person Info" v-model="dialogVisible" style="border-radius: 10px">
      <div>
        Name:
        <el-input v-model="userUpdate.name" width="auto"/>
      </div>
      <div>
        Establishment:
        <el-input type="text" v-model="userUpdate.establishment" width="auto"/>
      </div>
      <div>
        Occupation:
        <el-input type="text" v-model="userUpdate.occupation" width="auto" @keydown.enter="updateInfo"/>
      </div>
      <div style="margin-top: 2%;">
        <el-button type="danger" @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="updateInfo">Submit</el-button>
      </div>
    </el-dialog>
    <el-dialog title="Modify Password" v-model="dialogVisible1" style="border-radius: 10px">
      <div>
        New Password:
        <el-input type="text" v-model="userUpdate.password" width="auto" @keydown.enter="updatePassword"/>
      </div>
      <div style="margin-top: 2%;">
        <el-button type="danger" @click="dialogVisible1 = false">Cancel</el-button>
        <el-button type="primary" @click="updatePassword">Submit</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getRequest} from "@/utils/api";
import {putRequest} from "@/utils/api";
import {ElMessage} from "element-plus";

export default {
  name: "UserInfo",
  data() {
    return {
      user: {},
      userUpdate: {},
      userPassUp: {},
      dialogVisible: false,
      dialogVisible1: false,
      canDownload: false
    }
  },
  computed: {
    isDisabled() {
      if (this.canDownload) {
        return true;
      }
      if (this.user.establishment && this.user.name && this.user.occupation && this.user.establishment != '' && this.user.name != '' && this.user.occupation != '') {
        return false;
      }
      return true;
    }
  },
  mounted() {
    this.getPersonalInfo();
    this.canDown();
  },
  methods: {
    reqDownPer() {
      getRequest('/user/info/req/down/per').then(resp => {
        if (resp) {
          return;
        }
      })
    },
    canDown() {
      let dataJson = window.sessionStorage.getItem('user');
      let data = JSON.parse(dataJson);
      for (let i = 0; i < data.roles.length; i++) {
        if (data.roles[i].rid === 1 || data.roles[i].rid == 3 || data.roles[i].rid==2) {
          this.canDownload = true;
          break;
        }
      }
    },
    getPersonalInfo() {
      getRequest('/user/info').then(resp => {
        if (resp) {
          this.user = resp;
        }
      })
    },
    updateInfo() {
      if (Object.keys(this.userUpdate).length < 1) {
        ElMessage.error("Checked your enter!");
        return;
      }
      putRequest("/user/info/update", this.userUpdate).then(resp => {
        if (resp) {
          this.userUpdate = {};
        }
        this.getPersonalInfo();
        this.dialogVisible = false;
      })
    },
    updatePassword() {
      if (!(this.userUpdate.password && this.userUpdate.password != '')) {
        ElMessage.error("Empty value!");
        return;
      }
      if (!this.validatePassword()) {
        ElMessage.error("The password should contain an letter, a number, a special character and at least 8 characters.");
        this.confirmPassword = '';
        return;
      }
      putRequest("/user/info/update", this.userUpdate).then(resp => {
        if (resp) {
          this.userUpdate = {};
        }
      }).finally(() => {
        this.dialogVisible1 = false;
      })
    },
    validatePassword() {
      const password = this.userUpdate.password;
      // 对密码输入框进行验证，返回 true 表示验证通过，false 表示验证失败
      const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[a-zA-Z\d!@#$%^&*()_+]{8,}$/;
      return passwordRegex.test(password);
    },
    validateConfirmPassword() {
      const confirmPassword = this.confirmPassword;
      // 对确认密码输入框进行验证，返回 true 表示验证通过，false 表示验证失败
      return confirmPassword === this.registerForm.password;
    },
  },
}
</script>

<style scoped>

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 139%;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 500%;
  left: 20%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.modifyButton {
  background-color: #317b88;
  border-radius: 3px;
  border: none;
  padding: 8px 10px;
  cursor: pointer;
  color: white;
  font-size: 15px;
  margin-right: 13px;
  transition: all 0.02s ease-in-out;
}

.modifyButton:hover {
  background-color: rgba(68, 175, 194, 0.75);
  color: #2d2d2d;
}
</style>
