<template>
  <Form v-show="isShowLogin">
    <div style="display: flex;margin-left: 30%;">
      <h3 style="color: #5264AE;">Login</h3>
      <h3 style="color: rgba(141,138,138,0.47);">&nbsp;/&nbsp;</h3>
      <h3 class="login" @click="toggleLogin">Register</h3>
    </div>
    <div style="margin-top: -7%;">&nbsp;</div>
    <div class="input-container">
      <input type="text" id="username" v-model="loginForm.username" required placeholder=" " class="input-1">
      <span></span>
      <label for="username">Email</label>
    </div>
    <div class="input-container">
      <input type="password" id="password" v-model="loginForm.password" required placeholder=" " class="input-1">
      <span></span>
      <label for="password">Password</label>
    </div>
    <el-link style="margin-left: 79%;margin-top: -28%;" href="#/back">reset password</el-link>
    <button type="submit" @click="submitLogin">Log In</button>
    <div style="float: left;margin-top: 30px">
      <span style="color: #31465b;margin-left: 45px;margin-top: 50px;">* Log in for more function. </span>
    </div>
  </Form>
</template>

<script>
import {postKeyValueRequest} from "@/utils/api";
import {ElMessage} from "element-plus";

export default {
  name: "LoginForm",
  props: {
    isShowLogin: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    toggleLogin() {
      this.$emit('toggle-login', !this.isShowLogin);
    },
    submitLogin() {
      if (this.validate()) {
        window.sessionStorage.removeItem("user");
        this.$store.commit('initRoutes', [])
        this.$emit('toggle-loading', true);
        postKeyValueRequest('/doLogin', this.loginForm).then(resp => {
          if (resp) {
            window.sessionStorage.setItem("user", JSON.stringify(resp.obj));
            // console.log("-----")
            // console.log(this.$route.query)
            if (this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect);
            }else{
              // console.log("+++++")
              this.$router.push('/home');
            }
          }
        }).finally(() => {
          // 隐藏全屏loading
          this.$emit('toggle-loading', false);
        });
      } else {
        ElMessage.error('username or password error!');
      }
    },
    validate() {
      if (!this.validateUsername(this.loginForm.username)) {
        return false;
      }
      if (!this.validatePassword(this.loginForm.password)) {
        return false;
      }
      return true;
    },
    validateUsername(username) {
      // 对用户名输入框进行验证，返回 true 表示验证通过，false 表示验证失败
      for(let i = 0; i < username.length; i++){
        if(username.charAt(i)=='@'){
          // console.log("11111")
          return true;
        }
      }
      const usernameRegex = /^[a-zA-Z0-9]{6,18}$/;
      return usernameRegex.test(username);
    },
    validatePassword(password) {
      // 对密码输入框进行验证，返回 true 表示验证通过，false 表示验证失败
      // const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[a-zA-Z\d!@#$%^&*()_+]{8,}$/;
      // return passwordRegex.test(password);
      return true;
    },
  }
}
</script>

<style scoped>
.login:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  form {
    padding: 20px;
    max-width: 100%; /* Change the maximum width to 100% for smaller screens */
  }

  button[type="submit"] {
    margin-top: -6%;
    margin-left: 10%;
    /*padding: 5px 15px;*/
    background-color: #5264AE;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 17px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 84%;
  }
}

form {
  margin-left: 25%;
  margin-top: 1%; /* change to a smaller value */
  margin-bottom: 2%;
  height: 79%;
  width: 26%;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

button[type="submit"] {
  margin-top: 8%;
  margin-left: 10%;
  padding: 10px 25px;
  background-color: #5264AE;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 84%;
}

button[type="submit"]:hover {
  background-color: #46528C;
}

.input-container {
  margin-top: -1%;
  position: relative;
  margin: 72px auto;
  width: 80%;
}

input {
  font-size: 16px;
  padding: 8px 8px 8px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
}

input:focus {
  outline: none;
}

label {
  color: #999;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
}

input:focus ~ label,
input:not(:placeholder-shown) ~ label {
  top: -20px;
  font-size: 14px;
  color: #5264AE;
}
</style>
