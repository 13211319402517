import CryptoJS from "crypto-js";

const IV_STRING = "0000000000000000";
const AES_KEY = "1234567890123456";

const decryptionConfig = {
    iv: CryptoJS.enc.Utf8.parse(IV_STRING),
    key: CryptoJS.enc.Utf8.parse(AES_KEY),
    decryptData(data) {
        const decrypted = CryptoJS.AES.decrypt(data, this.key, { iv: this.iv });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }
};

export default decryptionConfig;
