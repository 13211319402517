<template>
  <el-container style="display: flex; flex-direction: column; height: 100%;">
    <el-header>
      <Header></Header>
    </el-header>
    <div class="box-card">
      <div style="display: flex;">
        <p style="font-weight: bolder;color: #335981;font-size: 19px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PDB Information</p>
        <p class="pdown" @click="download"><u>DOWNLOAD PDB FILE</u></p>
      </div>
      <hr style="margin-bottom: 20px;">
      <div class="controls" style="">
<!--        <button style="margin-left: -110px;border: none;font-size: 18px;border-radius:2px;font-family: Arial;background-color: #d3d2d2">style:</button>-->
        <button @click="setCartoonStyle">Cartoon</button>
        <button @click="setBallAndStickStyle">Ball&Stick</button>
        <button @click="highlightWaterMolecules">Water</button>
        <button @click="addSurface">Surface</button>
        <button @click="setCartoonStyle">Reset</button>
      </div>
      <div style="display: flex">
        <div style="width: 31%;margin-left: 3%;margin-top: 2%;">
          <div id="container-01" class="mol-container tooltip" ref="molContainer">
            <span class="tooltiptext">Use the mouse to move, rotate, and scale the model</span>
          </div>
          <!--          <div class="under-container">{{ this.pid }}</div>-->
        </div>
        <div style="margin-top: -18px;width: 70%">
          <el-descriptions border :column="1" size="default">
            <el-descriptions-item label="Pid">{{ this.protein.pid }}</el-descriptions-item>
            <el-descriptions-item label="Total Structure Weight">{{
                this.protein.totalStructureWeight
              }}
            </el-descriptions-item>
            <el-descriptions-item label="Atom Count">{{ this.protein.atomCount }}</el-descriptions-item>
            <el-descriptions-item label="Modelled Residue Count">{{
                this.protein.modelledResidueCount
              }}
            </el-descriptions-item>
            <el-descriptions-item label="Deposited Residue Count">{{
                this.protein.depositedResidueCount
              }}
            </el-descriptions-item>
            <el-descriptions-item label="Unique Protein Chains">{{
                this.protein.uniqueProteinChains
              }}
            </el-descriptions-item>
            <el-descriptions-item label="Description">{{ this.protein.description }}</el-descriptions-item>
            <el-descriptions-item label="Deposition Author">{{ this.protein.depositionAuthor }}</el-descriptions-item>
            <el-descriptions-item label="Release Date">{{ this.protein.releaseDate }}</el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <br><br><br>

      <div style="display: flex;margin-left:0%;width: 102%;font-weight: bold" v-show="showBind">

        <div v-show="showBind" style="border: #ececec 1px solid;padding: 23px;
                                    background-color: rgb(239,239,239);font-family: Courier, monospace;word-spacing: 0;">
          <div v-for="(chain,index) in chianWithSite.obj" :key="index+1000">
            <div style="background-color: #4f619d;color: white;border-radius: 4px;padding: 20px;white-space: nowrap;">
              <p>{{ chain.chain }}</p>
            </div>
            <div v-for="ligand in Object.keys(fmtSites(chain.sites))" style="margin-left: 10px;margin-top: 14px;">
              <div
                  style="background-color: #9f9f9f;color: white;border-bottom-left-radius: 14px;padding: 20px;">
                <p>{{ ligand }}</p>
              </div>
            </div>
            <br>
          </div>
        </div>


        <div v-show="showBind" style="border: #ececec 1px solid;padding: 23px;max-width: 81%;min-width:81%;overflow: auto;
                                    background-color: rgb(228,230,235);font-family: Courier, monospace;word-spacing: 0;"
             v-on:wheel.prevent="zoom" class="scrollbar">
          <div v-for="(chain,index) in chianWithSite.obj" :key="index+100" v-show="fontSize<18">
            <div>
              <div style="display: flex;padding: 20px;">
                <div v-for="c in fmtSeq(chain.sequence)" style="display: flex"
                     v-bind:style="{'width': 0.4*fontSize + 'px','font-size': 0.5*fontSize + 'px'}">
                  <p class="number" style="color: transparent">{{ c }}</p>
                  <p style="color:transparent;background-color: transparent">
                    &nbsp;&nbsp;&nbsp;;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                </div>
              </div>
            </div>
            <div v-for="ligand in Object.keys(fmtSites(chain.sites))">
              <div style="display: flex;margin-top: 12px;padding: 20px">
                <div v-for="c in showSeqChar(fmtSeq(chain.sequence),fmtSites(chain.sites)[ligand])"
                     style="display: flex"
                     v-bind:style="{'width': 0.4*fontSize + 'px','font-size': 0.5*fontSize + 'px'}">
                  <p class="number1" style="color: transparent;border-radius: 0px;">{{ c }}</p>
                  <p style="color: transparent;background-color: transparent">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;;&nbsp;&nbsp;</p>
                </div>
              </div>
            </div>
            <br><br>
          </div>

          <div v-for="(chain,index) in chianWithSite.obj" :key="index+100" v-show="fontSize>=18&&fontSize<21">
            <div>
              <div style="display: flex;padding: 20px;">
                <div v-for="c in fmtSeq(chain.sequence)" style="display: flex"
                     v-bind:style="{'width': 0.8*fontSize + 'px','font-size': 1.1*fontSize + 'px'}">
                  <p class="number" style="color: white">{{ c }}</p>
                  <p style="color: transparent;background-color: transparent">&nbsp;&nbsp;&nbsp;</p>
                </div>
              </div>
            </div>
            <div v-for="ligand in Object.keys(fmtSites(chain.sites))">
              <div style="display: flex;margin-top: 12px;padding: 20px">
                <div v-for="c in showSeqChar(fmtSeq(chain.sequence),fmtSites(chain.sites)[ligand])"
                     style="display: flex"
                     v-bind:style="{'width': 0.8*fontSize + 'px','font-size': 1.1*fontSize + 'px'}">
                  <p class="number1" style="color: white">{{ c }}</p>
                  <p style="color: transparent;background-color: transparent">&nbsp;&nbsp;&nbsp;</p>
                </div>
              </div>
            </div>
          </div>

          <div v-for="(chain,index) in chianWithSite.obj" :key="index+100" v-show="fontSize>=21&&fontSize<23">
            <div>
              <div style="display: flex;padding: 20px;margin-top: 4px">
                <div v-for="c in fmtSeq(chain.sequence)" style="display: flex"
                     v-bind:style="{'width': 0.8*fontSize + 'px','font-size': 1.1*fontSize + 'px'}">
                  <p class="number" style="color: white;border-radius: 3px">{{ c }}</p>
                  <p style="color: transparent;background-color: transparent">&nbsp;&nbsp;&nbsp;</p>
                </div>
              </div>
            </div>
            <div v-for="ligand in Object.keys(fmtSites(chain.sites))">
              <div style="display: flex;margin-top: 3px;padding: 20px">
                <div v-for="c in showSeqChar(fmtSeq(chain.sequence),fmtSites(chain.sites)[ligand])"
                     style="display: flex"
                     v-bind:style="{'width': 0.8*fontSize + 'px','font-size': 1.1*fontSize + 'px'}">
                  <p class="number1" style="color: white;border-radius: 3px">{{ c }}</p>
                  <p style="color: transparent;background-color: transparent">&nbsp;&nbsp;&nbsp;</p>
                </div>
              </div>
            </div>
          </div>

          <div v-for="(chain,index) in chianWithSite.obj" :key="index+100" v-show="fontSize>=23">
            <div>
              <div style="display: flex;padding: 20px;margin-top: 4px">
                <div v-for="c in fmtSeq(chain.sequence)" style="display: flex"
                     v-bind:style="{'width': 0.8*fontSize + 'px','font-size': 1.1*fontSize + 'px'}">
                  <p class="number" style="color: white;border-radius: 3px">{{ c }}</p>
                  <p style="color: transparent;background-color: transparent">&nbsp;&nbsp;&nbsp;</p>
                </div>
              </div>
            </div>
            <div v-for="ligand in Object.keys(fmtSites(chain.sites))">
              <div style="display: flex;margin-top: -3px;padding: 20px">
                <div v-for="c in showSeqChar(fmtSeq(chain.sequence),fmtSites(chain.sites)[ligand])"
                     style="display: flex"
                     v-bind:style="{'width': 0.8*fontSize + 'px','font-size': 1.1*fontSize + 'px'}">
                  <p class="number1" style="color: white;border-radius: 3px">{{ c }}</p>
                  <p style="color: transparent;background-color: transparent">&nbsp;&nbsp;&nbsp;</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br><br><br>

      </div>
      <br><br>
      <table style="margin-top: 23px;width: 99%;margin-left: 2px">
        <thead>
        <tr>
          <th>Entity Id</th>
          <th>Molecule</th>
          <th>Chains</th>
          <th>Sequence Length</th>
          <th>Organism</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="chain in this.protein.chains" :key="chain.entityId">
          <td class="tdLink">{{ chain.entityId }}</td>
          <td class="tdLink">{{ chain.molecule }}</td>
          <td>{{ chain.chains }}</td>
          <td>{{ chain.sequenceLength }}</td>
          <td>{{ chain.organism }}</td>
        </tr>
        </tbody>
      </table>
      <div>

      </div>
      <br><br><br><br><br><el-footer>
      <Footer></Footer>
    </el-footer>
    </div>

  </el-container>
</template>

<script>
import * as $3Dmol from "3dmol"
import {getRequest} from "@/utils/api";
// import decryptionConfig from "../utils/decryption";
import Header from "@/views/Index/Header.vue";
import {ElMessage} from "element-plus";
import Footer from "@/views/Index/Footer.vue";

export default {
  name: "PdbView",
  components: {Footer, Header},
  data() {
    return {
      pid: '',
      protein: {},
      viewer: null,
      sites: [],
      fontSize: 16,
      showBind: false,
      chianWithSite: [],
      activeSite: [],
      colorTheme: {
        '1': '',
        '2': '',
        '3': '',
        '4': '',
        '5': '',
        '6': '',
        '7': '',
      }
    }
  },
  created() {
    this.viewer = null;
  },
  mounted() {
    // 假设样式字符串为:
    let styleStr = "29-30,32,52-54,56-57,62,101-106,108,110-111,113";

// 将样式字符串分割为单独的数字和数字范围
    let styleParts = styleStr.split(",");
    let nums = [];
    for (let i = 0; i < styleParts.length; i++) {
      if (styleParts[i].indexOf("-") !== -1) {
        let rangeParts = styleParts[i].split("-");
        let start = parseInt(rangeParts[0]);
        let end = parseInt(rangeParts[1]);
        for (let j = start; j <= end; j++) {
          nums.push(j);
        }
      } else {
        nums.push(parseInt(styleParts[i]));
      }
    }

// 将数字列表排序并打印
    nums.sort(function (a, b) {
      return a - b;
    });
    // console.log(nums);


    //从路由里获取当前pid
    this.pid = this.$route.query.pid
    this.viewer = $3Dmol.createViewer(this.$refs.molContainer, {});

    //获取protein数据
    getRequest('/protein/info/' + this.pid).then(resp => {
      if (resp) {
        this.protein = resp.obj;
        this.viewer.addModel(this.protein.pdbStr, 'pdb');
        // this.setBallAndStickStyle();
        this.setCartoonStyle();
        this.viewer.zoomTo();
        this.viewer.render();
        this.viewer.zoom(1.2, 1000);
      }
    });

    this.getSites();
    this.getActiveSite();

  },
  methods: {
    getActiveSite() {
      getRequest('/activesite/' + this.pid).then(resp => {
        if (resp) {
          let temp = resp;
          if (temp.length > 0) {
            this.activeSite = temp;
          } else {
            return;
          }
          // console.log(temp)
          for (let i = 0; i < temp.length; i++) {
            const string = temp[i].activeSite;
            const array = string.split('\n');
            if(array[array.length-1]==''){

            }
            // console.log(array)
          }
          // if the final elem is '',pop it

        }
      })
    },
    zoom(event) {
      const delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
      const zoom = 0.1;
      const scale = Math.exp(delta * zoom);
      const currentFontSize = this.fontSize;
      const newFontSize = currentFontSize * scale;
      this.fontSize = newFontSize;
      if (this.fontSize >= 24) {
        this.fontSize = 24;
      }
      if (this.fontSize <= 16) {
        this.fontSize = 16;
      }
    },
    showSeqChar(sequence, indexs) {
      let result = sequence.split('').map((char, index) => {
        if (indexs.includes(index)) {
          return char;
        } else {
          return ' ';
        }
      }).join('');
      return result;
    },
    fmtSeq(chain) {
      return chain.replace(/\s+/g, '');
    },
    fmtSites(sites) {
      // ['SO4:40,45,46', 'MPD:62,63,64']
      let site = [];
      let temp = '';
      for (let i = 0; i < sites.length; i++) {
        if (temp.length > 2 && sites[i].match(/[a-zA-Z]/)) {
          site.push(temp.replace(/\s+/g, ''));
          temp = '';
          // console.log(site)
        }
        temp += sites[i];
        if (i == sites.length - 1) {
          site.push(temp.replace(/\s+/g, ''));
        }
      }
      const obj = {};
      for (let i = 0; i < site.length; i++) {
        const [key, valueStr] = site[i].split(":");
        const value = valueStr.split(",").map(Number);
        obj[key] = value;
      }
      return obj;
    },
    getSites() {
      getRequest('/protein/bindsite/' + this.pid).then(resp => {
        if (resp) {
          let temp = resp;
          if (temp.obj.length > 0) {
            this.chianWithSite = temp;
            this.showBind = true;
          } else {
            return;
          }
        }
      })
    },
    download() {
      let temp;
      let dataJson = window.sessionStorage.getItem('user');
      if (!dataJson) {
        this.$router.push({name: "Login"});
      } else {


            getRequest("/protein/download/" + this.pid).then(resp => {
              if (resp) {
                this.saveAsPDBFile(this.pid, resp.obj)
              }
            });
            return;


        ElMessage.error("check your permission!");
        this.$router.push("/user/info");
      }
    },
    saveAsPDBFile(pid, str) {
      const blob = new Blob([str], {type: 'chemical/x-pdb'});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = pid + '.pdb';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    setCartoonStyle() {
      this.resetStyle();
      this.viewer.setStyle({}, {'cartoon': {'color': 'spectrum'}});
      this.viewer.render();
    },
    resetStyle() {
      this.viewer.removeAllSurfaces();
      // this.setCartoonStyle()
    },
    setBallAndStickStyle() {
      this.viewer.setStyle({}, {stick: {colorscheme: 'chain'}, sphere: {radius: 0.2}});
      this.viewer.render();
    },
    setHelixStyle(helixType) {
      this.resetStyle();
      let selector;
      if (helixType === 'alpha') {
        selector = {resn: ['ALA', 'ARG', 'ASN', 'ASP', 'CYS', 'GLN', 'GLU', 'GLY', 'HIS', 'ILE', 'LEU', 'LYS', 'MET', 'PHE', 'PRO', 'SER', 'THR', 'TRP', 'TYR', 'VAL']};
      } else if (helixType === 'pi') {
        selector = {resn: ['ILE', 'VAL']};
      } else if (helixType === '7/2') {
        selector = {resn: ['LYS', 'MET', 'VAL']};
      } else {
        console.error('Unknown helix type:', helixType);
        return;
      }
      let viewer = this.viewer;
      viewer.setStyle(selector, {'color': 'spectrum', 'style': 'oval'});
      viewer.render();
    },
    highlightWaterMolecules() {
      this.resetStyle();
      // 水选择器
      let waterSelector = {resn: ['HOH', 'TIP', 'TP3', 'SPC', 'WAT', 'H2O']};
      // 设置空间充满样式
      this.viewer.setStyle(waterSelector, {
        'stick': {'colorscheme': 'greenCarbon'},
        'sphere': {'opacity': 0.4, 'radius': 0.5, 'colorscheme': 'greenCarbon'},
        'line': {'hidden': false, 'radius': 0.08, 'colorscheme': 'greenCarbon'}
      });
      // 将水原子聚合在一起，以突出显示
      this.viewer.zoomTo(waterSelector);
      this.viewer.render();
    },
    addSurface() {
      // 访问viewer对象
      let viewer = this.viewer
      let surfaceRange = [{
        color: '0x00ff00', //表面颜色，十六进制值
        opacity: 0.7, //表面透明度，介于0和1之间
        wireframe: false, //是否显示线框模式
        smoothness: 80, //表面的光滑程度，介于0和100之间
        scaleFactor: 1, //表面缩放因子
        voldata: 'pdb', //用于绘制表面的体数据类型
        cutoff: 0, //在绘制表面时剪切体数据的最小值
        nProbes: 500, //在计算表面时使用的探针数量
        volscheme: $3Dmol.Gradient.RWB, //颜色梯度方案
        colorscheme: $3Dmol.elementColors.rasmol, //用于颜色着色的元素颜色方案
        useWorker: true //是否在Web Worker中运行计算表面的算法
      }];
      //添加表面
      viewer.addSurface(surfaceRange, {voldata: 'pdb'});
      this.isBallAndStickStyleApplied = true;
      this.setBallAndStickStyle();
    },
  }
}
</script>

<style scoped>

.scrollbar::-webkit-scrollbar {
  cursor: pointer;
  height: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: rgb(156, 199, 139);
}

p {
  margin: 0;
  padding: 0;
}

.number {
  background-color: #57af05;
  color: white;
  justify-content: center;
}

.number1 {
  background-color: #d35100;
  color: white;
  justify-content: center;
}


table {
  border-collapse: collapse;
  width: 95%;
  font-family: Arial;
  font-size: 15px;
  margin-left: 4%;
  border: 1px solid #efeded;
}

th {
  text-align: left;
  padding: 8px 8px;
  border: 1px solid #efeded;
}

td {
  text-align: left;
  padding: 11px 8px;
  border: 1px solid #efeded;
}

th {
  background-color: rgba(8, 28, 75, 0.71);
  color: white;
}

tr:nth-child(even) {
  background-color: #efefef;
}


.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 60%;
  background-color: rgba(0, 0, 0, 0.72);
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 105%;
  left: 20%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}


.pdown:hover {
  color: rgba(246, 75, 75, 0.8);
  transform: scale(1.05);
}

.pdown {
  /*font-weight: normal;*/
  color: #345a82;
  font-size: 19px;
  margin-left: 66%;
  cursor: pointer;
}

.box-card {
  padding: 10px;
  height: 80vh;
  /*border: 1px solid rgba(52, 90, 130, 0.25);*/
  /*border-radius: 3px;*/
  /*box-shadow: 4px 5px 11px rgba(171, 171, 171, 0.3);*/
  margin-left: 5%;
  background-color: transparent;
  margin-top: 1%;
  width: 90%;
  z-index: 3;
  background: transparent;
}

hr {
  height: 2px;
  border: none;
  background: #345a82;
  background-size: 200% auto;
  animation: rainbow 60s ease-in-out infinite;
}

@keyframes rainbow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.mol-container {
  width: 90%;
  height: 311px;
  position: relative;
  border: 1px solid #f6f6f6;
  box-shadow: 0 1px 6px rgba(134, 129, 129, 0.29);
}

.controls {
  margin-top: 2%;
  margin-left: 3%;
  width: 28%;
  display: flex;
  justify-content: space-between;
}

button {
  /*margin-left: 1px;*/
  padding: 8px;
  border: 2px solid rgb(52, 90, 130);
  border-radius: 5px;
  background-color: transparent;
  color: rgb(52, 90, 130);
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
  /*box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);*/
  transition: all 0.3s ease;
  outline: none; /* 移除按钮聚焦时的蓝色边框 */
}

button:hover {
  background-color: #7e9cbd;
  color: white;
  /*box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);*/
  transition: all 0.3s ease;
}

button span {
  display: inline-block;
  transition: all 0.3s ease;
}

button:focus {
  /*box-shadow: 0px 0px 5px #333; !* 添加聚焦时的阴影效果 *!*/
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.under-container {
  margin-top: 1px;
  border: 2px solid rgb(255, 255, 251);
  font-size: 18px;
  color: black;
  background-color: rgb(255, 255, 251);
  width: 90%;
  text-align: center;
  border-radius: 1px;
}
</style>
