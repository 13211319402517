<template>
  <div style="background-color: white;">
    <div id="container-01" class="mol-container" ref="molContainer">
    </div>
    <el-input v-model="pid" placeholder="" style="width: 288px;">

    </el-input>
    <br>
    A
    <el-input v-model="colorChainA" style="width: 200px;" placeholder="A:color">

    </el-input>
    <br>
    B
    <el-input v-model="colorChainB" style="width: 200px;" placeholder="B:color">

    </el-input>
    <br>
    B
    <el-input v-model="colorChainC" style="width: 200px;" placeholder="C:color">

    </el-input>
    <br>
    D
    <el-input v-model="colorChainD" style="width: 200px;" placeholder="D:color">

    </el-input>
    <br>
    E
    <el-input v-model="colorChainE" style="width: 200px;" placeholder="E:color">

    </el-input>
    <br>
    F
    <el-input v-model="colorChainF" style="width: 200px;" placeholder="F:color">

    </el-input>
    <br>
    G
    <el-input v-model="colorChainG" style="width: 200px;" placeholder="G:color">

    </el-input>
    <br>
    <el-input v-model="point1Chain" style="width: 200px;" placeholder="point1">

    </el-input>
    <el-input v-model="point1" style="width: 200px;" placeholder="point1">

    </el-input>

    <el-input v-model="point1Color" style="width: 200px;" placeholder="point1Color">

    </el-input>
    <br>
    <el-input v-model="point2Chain" style="width: 200px;" placeholder="point2">

    </el-input>
    <el-input v-model="point2" style="width: 200px;" placeholder="point2">

    </el-input>

    <el-input v-model="point2Color" style="width: 200px;" placeholder="point2Color">

    </el-input>
    <br>
    <el-input v-model="point3Chain" style="width: 200px;" placeholder="point3">

    </el-input>
    <el-input v-model="point3" style="width: 200px;" placeholder="point3">

    </el-input>

    <el-input v-model="point3Color" style="width: 200px;" placeholder="point3Color">

    </el-input>
    <br>
    <el-input v-model="point4Chain" style="width: 200px;" placeholder="point4">

    </el-input>
    <el-input v-model="point4" style="width: 200px;" placeholder="point4">

    </el-input>

    <el-input v-model="point4Color" style="width: 200px;" placeholder="point4Color">

    </el-input>
    <br>
    <el-input v-model="point5Chain" style="width: 200px;" placeholder="point2">

    </el-input>
    <el-input v-model="point5" style="width: 200px;" placeholder="point5">

    </el-input>

    <el-input v-model="point5Color" style="width: 200px;" placeholder="point5Color">

    </el-input>
    <br>
    <el-input v-model="point6Chain" style="width: 200px;" placeholder="point6">

    </el-input>
    <el-input v-model="point6" style="width: 200px;" placeholder="point6">

    </el-input>

    <el-input v-model="point6Color" style="width: 200px;" placeholder="point6Color">

    </el-input>
    <br>
    <el-input v-model="point7Chain" style="width: 200px;" placeholder="point7">

    </el-input>
    <el-input v-model="point7" style="width: 200px;" placeholder="point7">

    </el-input>

    <el-input v-model="point7Color" style="width: 200px;" placeholder="point7Color">

    </el-input>


    <!--    <el-input v-model="point" placeholder="[1,2,3,4...]" style="width: 200px;">-->

    <!--    </el-input>-->
    <!--    <el-input v-model="color" placeholder="red、gold、green、black..." style="width: 200px;">-->

    <!--    </el-input>-->
    <!--    <el-input v-model="color1" placeholder="red、gold、green、black...(pointColor)" style="width: 200px;">-->

    <!--    </el-input>-->
    <el-button type="default" @click="setStyle">
      画
    </el-button>
    <br>
    <!--    <div style="display: flex;">-->
    <!--      <div style="max-width: 1000px;overflow: auto;display: flex">-->
    <!--        <span v-for="c in seq" style="display: flex;">-->
    <!--          <p style="background-color: #34a634;color: white;width: 14px;justify-content: center;">{{ c }}</p><p style="background-color: white;width:3px"></p>-->
    <!--        </span>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="container" style="font-family: Courier, monospace;word-spacing: 0;height: 300px">
      <div class="content scrollbar" v-on:wheel.prevent="zoom">
        <div style="display: flex" v-show="fontSize>18">
          <div v-for="c in seq" class="item"
               v-bind:style="{'width': 0.6*fontSize + 'px','font-size': 0.8*fontSize + 'px'}">
            <p class="number">{{ c }}</p>
            <p style="color: white;background-color: white">&nbsp;&nbsp;&nbsp;</p>
          </div>
        </div>
        <div style="display: flex;margin-top: -30px;" v-show="fontSize>18">
          <div v-for="c in showSeqChar()" class="item"
               v-bind:style="{'width': 0.6*fontSize + 'px','font-size': 0.8*fontSize + 'px'}">
            <p class="number">{{ c }}</p>
            <p style="color: white;background-color: white">&nbsp;&nbsp;&nbsp;</p>
          </div>
        </div>
        <div style="display: flex" v-show="fontSize<=15">
          <div v-for="c in seq" class="item"
               v-bind:style="{'width': 0.4*fontSize + 'px','font-size': 0.5*fontSize + 'px'}">
            <p style="background-color: #34a634;color: transparent;">{{ c }}</p>
            <p style="color: white;background-color: white">&nbsp;</p>
          </div>
        </div>
        <div style="display: flex;" v-show="fontSize<=15">
          <div v-for="c in showSeqChar()" class="item"
               v-bind:style="{'width': 0.4*fontSize + 'px','font-size': 0.5*fontSize + 'px'}">
            <p style="background-color: #34a634;color: transparent;">{{ c }}</p>
            <p style="color: white;background-color: white">&nbsp;</p>
          </div>
        </div>
        <div style="display: flex" v-show="fontSize<=18&&fontSize>15">
          <div v-for="c in seq" class="item"
               v-bind:style="{'width': 0.4*fontSize + 'px','font-size': 0.5*fontSize + 'px'}">
            <p style="background-color: #34a634;color: #d9d9d9;">{{ c }}</p>
            <p style="color: white;background-color: white">&nbsp;</p>
          </div>
        </div>
        <div style="display: flex;" v-show="fontSize<=18&&fontSize>15">
          <div v-for="c in showSeqChar()" class="item"
               v-bind:style="{'width': 0.4*fontSize + 'px','font-size': 0.5*fontSize + 'px'}">
            <p style="background-color: #34a634;color: #d9d9d9;">{{ c }}</p>
            <p style="color: white;background-color: white">&nbsp;</p>
          </div>
        </div>
      </div>
    </div>
    <br><br><br><br><br><br><br><br><br><br><br><br>
    <div class="container" style="font-family: Courier, monospace;word-spacing: 0;height: 300px">
      <div class="content scrollbar" v-on:wheel.prevent="zoom">
        <div style="display: flex" v-show="fontSize<18">
          <div v-for="(chain,index) in draw" :key="index" >
            <div v-for="(c,index) in fmtSeq(chain)" class="item" :key="index" style="display: flex"
                 v-bind:style="{'width': 0.6*fontSize + 'px','font-size': 0.8*fontSize + 'px'}">
              <p class="number" style="display: flex">{{ c }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import * as $3Dmol from "3dmol"
import {getRequest} from "@/utils/api";

export default {
  name: "viewTest",
  data() {
    return {
      pid: '1op9',
      protein: {},
      viewer: null,
      point: '',
      colorChainA: 'red',
      colorChainB: 'blue',
      colorChainC: 'white',
      colorChainD: 'white',
      colorChainE: 'white',
      colorChainF: 'white',
      colorChainG: 'white',
      point1Chain: 'A',
      point2Chain: 'B',
      point1: '1,2,3,7,10-12',
      point2: '1,2,5-7',
      point1Color: 'gold',
      point2Color: 'green',

      point3Chain: 'C',
      point4Chain: 'D',
      point3: '',
      point4: '',
      point3Color: '',
      point4Color: '',

      point5Chain: 'E',
      point6Chain: 'F',
      point5: '',
      point6: '',
      point5Color: '',
      point6Color: '',

      point7Chain: 'G',
      point7: '',
      point7Color: '',
      seq: 'QVQLVESGGGLVQPGGSLTLSCTASGFTLDHYDIGWFRQAPGKEREGVSCINNSDDDTYYADSVKGRFTIFMNNAKDTVYLQMNSLKPEDTAIYYCAEARGCKRGRYEYDFWGQGTQVTVSSKKKHHHHHH',
      seqLen: 131,
      sites: 'MPD: 37,49,50,99,102,103',
      sites1: [{
        'MPD': [37, 49, 50, 99, 102, 103]
      }],
      fontSize: 12,
      draw: [
        'GQVQLQESGGGLVQAGGSLRLSCVASGRTFSSYAMSEGWFRQAPGKEREFVAAIDWSGGTASHADSVKGRFTISRDNAKNTVYLQ MSE NSLKPEDTAVYYCAASSYWSRSVDEYDYWGQGTQVTVSS\n',
        'GQVQLQESGGGSVQAGGSLRLSCAASGRTFSKNAMGWFRQAPGKEREFVVAISWSGRNTYYADSVKGRFTISRDNAKNTVDLQMNSLKPEDSAVYYCAVGGDWRVYDISFYYTAHQYEYWGQGTQVTVSS\n'
      ],
      p1: {"SO4": [40, 45, 46], "MPD": [62, 63, 64]},
      p2: {"SO4": [28, 29, 30, 31, 113, 114, 115]}
    }
  },
  created() {
    this.viewer = null;
  },
  mounted() {
    this.viewer = $3Dmol.createViewer(this.$refs.molContainer, {});

    //获取protein数据
    getRequest('/protein/info/' + this.pid).then(resp => {
      if (resp) {
        this.protein = resp.obj;
        this.viewer.addModel(this.protein.pdbStr, 'pdb');
        // this.setBallAndStickStyle();
        this.setCartoonStyle();
        this.viewer.zoomTo();
        this.viewer.render();
        this.viewer.zoom(1.2, 1000);
      }
    });
  },
  methods: {
    fmtSeq(chain) {
      return chain.replace(/\s+/g, '');
    }
    ,
    showSeqChar() {
      let seq = this.seq;
      let seq1 = this.seq;
      var indexs = this.sites1[0]['MPD'];
      let result = seq.split('').map((char, index) => {
        if (indexs.includes(index)) {
          return char;
        } else {
          return ' ';
        }
      }).join('');
      return result;
    },
    zoom(event) {
      const delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
      const zoom = 0.1;
      const scale = Math.exp(delta * zoom);
      const currentFontSize = this.fontSize;
      const newFontSize = currentFontSize * scale;
      this.fontSize = newFontSize;
      if (this.fontSize >= 45) {
        this.fontSize = 45;
      }
      if (this.fontSize <= 12) {
        this.fontSize = 12;
      }
    },
    zoomIn() {
      this.fontSize *= 1.1;
    },
    zoomOut() {
      this.fontSize *= 0.9;
    },
    setStyle() {
      getRequest('/protein/info/' + this.pid).then(resp => {
        if (resp) {
          this.protein = resp.obj;
          this.viewer = null;
          this.viewer = $3Dmol.createViewer(this.$refs.molContainer, {});
          this.viewer.addModel(this.protein.pdbStr, 'pdb');
          // this.setBallAndStickStyle();
          this.setCartoonStyle();
          this.viewer.zoomTo();
          this.viewer.render();
          this.viewer.zoom(1.2, 1000);
        }
      }).finally(() => {
        // console.log(this.colorChainA)
        this.viewer.setStyle({chain: 'A'}, {cartoon: {color: this.colorChainA}});
        this.viewer.setStyle({chain: 'B'}, {cartoon: {color: this.colorChainB}});
        this.viewer.setStyle({chain: 'C'}, {cartoon: {color: this.colorChainC}});
        this.viewer.setStyle({chain: 'D'}, {cartoon: {color: this.colorChainD}});
        this.viewer.setStyle({chain: 'E'}, {cartoon: {color: this.colorChainE}});
        this.viewer.setStyle({chain: 'F'}, {cartoon: {color: this.colorChainF}});
        this.viewer.setStyle({chain: 'G'}, {cartoon: {color: this.colorChainG}});

        let point1 = this.getArray(this.point1);
        let point2 = this.getArray(this.point2);
        let point3 = this.getArray(this.point3);
        let point4 = this.getArray(this.point4);
        let point5 = this.getArray(this.point5);
        let point6 = this.getArray(this.point6);
        let point7 = this.getArray(this.point7);

        this.viewer.setStyle({chain: this.point1Chain, resi: point1}, {cartoon: {color: this.point1Color}});

        this.viewer.setStyle({chain: this.point2Chain, resi: point2}, {cartoon: {color: this.point2Color}});
        this.viewer.setStyle({chain: this.point3Chain, resi: point3}, {cartoon: {color: this.point3Color}});

        this.viewer.setStyle({chain: this.point4Chain, resi: point4}, {cartoon: {color: this.point4Color}});
        this.viewer.setStyle({chain: this.point5Chain, resi: point5}, {cartoon: {color: this.point5Color}});

        this.viewer.setStyle({chain: this.point6Chain, resi: point6}, {cartoon: {color: this.point6Color}});
        this.viewer.setStyle({chain: this.point7Chain, resi: point7}, {cartoon: {color: this.point7Color}});

        this.viewer.zoomTo();
        this.viewer.render();
        this.viewer.zoom(1.2, 1000);
      });
    },
    getArray(str) {
      // console.log("[1,2,3-4,6]".split(/[,\]-]/g).filter(item => item !== "" && !isNaN(item)).map(item => +item))
      let point = [];
      let item = '';
      for (let i = 0; i < str.length; i++) {
        if (str.at(i) != ',') {
          if (i == str.length - 1) {
            item += str.at(i);
            point.push(item);
          }
          item += str.at(i);
        } else {
          point.push(item);
          item = '';
        }
      }
      // console.log(point);
      return point;
    },
    setPointStyle(point, color) {
      let p = this.getArray(this.pdbStr);
      this.viewer.setStyle({resi: point}, {cartoon: {color: color}});
    },
    setChainStyle(chain, color) {
      this.viewer.setStyle({chain: chain}, {cartoon: {color: color}});
    },
    setCartoonStyle() {
      this.viewer.setStyle({}, {'cartoon': {'color': 'white'}});
      this.viewer.render();
    },

  }
}
</script>

<style scoped>

.scrollbar::-webkit-scrollbar {
  cursor: pointer;
  height: 11px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: rgba(210, 126, 101, 0.45);
}

.container {

  display: flex;
}

.content {
  overflow: auto;
  margin-left: 100px;
  width: 1388px;
  max-width: 1888px;
}

.item {
  display: flex;
}

.number {
  background-color: #62af8d;
  color: white;
  /*width: 14px;*/
  /*padding: 2px;*/
  justify-content: center;
}


table {
  border-collapse: collapse;
  width: 95%;
  font-family: Arial;
  font-size: 15px;
  margin-left: 4%;
  /*color: rgba(255, 255, 255, 0.44);*/
  border: 1px solid #efeded;
}

th {
  text-align: left;
  padding: 8px 8px;
  border: 1px solid #efeded;
}

td {
  text-align: left;
  padding: 11px 8px;
  border: 1px solid #efeded;
}

th {
  background-color: rgba(8, 28, 75, 0.71);
  color: white;
}

tr:nth-child(even) {
  background-color: #efefef;
}


.tooltip .tooltiptext {
  visibility: hidden;
  width: 60%;
  background-color: rgba(0, 0, 0, 0.72);
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 105%;
  left: 20%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}


hr {
  height: 2px;
  border: none;
  background: #345a82;
  background-size: 200% auto;
  animation: rainbow 60s ease-in-out infinite;
}

@keyframes rainbow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.mol-container {
  width: 90%;
  height: 311px;
  position: relative;
  border: 1px solid #f6f6f6;
  box-shadow: 0 1px 6px rgba(134, 129, 129, 0.29);
}

.controls {
  margin-top: 2%;
  margin-left: 3%;
  width: 28%;
  display: flex;
  justify-content: space-between;
}

button {
  margin-left: 20px;
  width: 66px;
  padding: 8px;
  border: 2px solid rgb(52, 90, 130);
  border-radius: 5px;
  background-color: rgb(52, 90, 130);
  color: whitesmoke;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
  /*box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);*/
  transition: all 0.3s ease;
  outline: none; /* 移除按钮聚焦时的蓝色边框 */
}

button:hover {
  background-color: #7e9cbd;
  color: white;
  /*box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);*/
  transition: all 0.3s ease;
}

button span {
  display: inline-block;
  transition: all 0.3s ease;
}

button:focus {
  /*box-shadow: 0px 0px 5px #333; !* 添加聚焦时的阴影效果 *!*/
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.under-container {
  margin-top: 1px;
  border: 2px solid rgb(255, 255, 251);
  font-size: 18px;
  color: black;
  background-color: rgb(255, 255, 251);
  width: 90%;
  text-align: center;
  border-radius: 1px;
}
</style>
