<template>
  <el-container style="background-color: #f3f3f3;position: absolute;left: 0;right: 0;">
    <el-header>
      <Header></Header>
    </el-header>
    <el-main>
      <div v-show="!isSearch" class="left-img-box" style="float: left;margin-left:100px;margin-top: 160px;">
        <img src="@/assets/new/lbs.png">
      </div>
      <div style="justify-content: left;display: flex;border: none;margin-top: 40px;" v-show="!isSearch">
        <div style="font-size: 56px;font-family: Arial;color: #6a4760"><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ligand
          binding sites Search</p></div>
      </div>
      <div v-show="!isSearch" style="justify-content: center;border-bottom: rgba(238,238,238,0.82) 1px solid;margin-top:33px;
      background-color:rgba(255,255,255,0.09);color: rgba(84,84,84,0.94);
      width: 58%;margin-left: 14%;font-size: 18px;line-height: 1.6;">
        <div style="display: flex;margin-bottom: 66px;margin-left: 29%;margin-top: 66px;">
          <button
              style="height:51px;width: 99px;border: slategrey 1px solid;background-color: #8b959b;border-radius: 1px;color: white;font-size: 16px;">
            Pid
          </button>
          <input type="text" placeholder="pid" v-model="pid" @keydown.enter="pidListSearch" style="width: 688px">
          <img src="@/assets/search.png" width="24" height="24"
               style="margin-left:35px;margin-top:6px;background-color: rgba(224,224,224,0.6);border-radius:5px;padding:12px;cursor:pointer;"
               @click="pidListSearch">
        </div>
        <div style="display: flex;margin-bottom: 66px;margin-left: 29%">
          <button
              style="height:51px;width: 99px;border: slategrey 1px solid;background-color: #8b959b;border-radius: 1px;color: white;font-size: 16px;margin-right: 0px;">
            Ligand
          </button>
          <input type="text" placeholder="ligand" v-model="ligand" @keydown.enter="ligandListSearch"
                 style="width: 688px">
          <img src="@/assets/search.png" width="24" height="24"
               style="margin-left:35px;margin-top:6px;background-color: rgba(224,224,224,0.6);border-radius:5px;padding:12px;cursor:pointer;"
               @click="ligandListSearch">
        </div>

        <div style="display: flex;margin-bottom: 66px;margin-left: 29%">
          <button
              style="height:51px;width: 99px;border: slategrey 1px solid;background-color: #8b959b;border-radius: 1px;color: white;font-size: 16px;margin-right: 0px;">
            Organism
          </button>
          <input type="text" placeholder="organism" v-model="organism" @keydown.enter="orgListSearch"
                 style="width: 688px">
          <img src="@/assets/search.png" width="24" height="24"
               style="margin-left:35px;margin-top:6px;background-color: rgba(224,224,224,0.6);border-radius:5px;padding:12px;cursor:pointer;"
               @click="orgListSearch">
        </div>
        <div style="margin-left: 310px;margin-top: 45px;">
          <span style="font-family: Arial;font-size: 17px;color: #626262"> * Search for the information of ligand binding sites, include chain information,ligand and ligand binding site at chain.</span>

        </div>
      </div>
      <br>
      <div style="justify-content: center;margin-left: 0%;" v-show="isSearch">
        <div style="margin-left: 10%;font-weight: normal;color: #496686;font-family: 'Book Antiqua'">
          <el-button
              style="background-color: rgba(229,217,131,0.86); color: #003079; width: 151px;height: 32px;border:none;font-family: Arial;font-size: 16px;cursor: pointer;">
            Total&nbsp; :&nbsp;&nbsp;{{ total }}
          </el-button>&nbsp;&nbsp;
          <el-button
              style="margin-left:10px;background-color: rgba(117,155,117,0.86); color: #ffffff; width: 288px;height: 32px;border:none;font-family: Arial;font-size: 16px;cursor: pointer;">
            searchType-Binding site :&nbsp;&nbsp;{{ searchType }}
          </el-button>&nbsp;&nbsp;
          <el-button @click="this.isSearch=false;"
                     style="margin-left:50%;background-color: #ffbbb4; color: #3f29ff; width: 131px;height: 32px;border:none;font-family: Arial;font-size: 16px;cursor:pointer;">
            Back
          </el-button>&nbsp;&nbsp;
        </div>
        <br>
        <el-pagination
            style="margin-left: 13%;margin-top: 10px;"
            pager-count="15"
            background
            :current-page="currentPage"
            :page-size="size"
            :page-sizes="[15,30,60,120,240]"
            @size-change="handleSizeChange"
            :total="total"
            layout="prev,  pager,  next, sizes,  jumper"
            @current-change="handleCurrentChange"
        ></el-pagination>
        <br><br>
        <el-table
            ref="table"
            :data="dataList"
            highlight-current-row
            style="width: 85%;margin-left: 7%;"
            :selectable="false"
            :select-on-indeterminate="false">
          <el-table-column label="Pid" prop="pid" width="123" align="center">
            <template #default="scope">
              <el-button v-if="searchType=='pid'" v-html="formatHightLight(scope.row.pid)"
                         @click="goToShow(scope.row.pid)" type="text">
              </el-button>
              <div v-else>
                <el-button type="text" @click="goToShow(scope.row.pid)">{{ scope.row.pid }}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Chain" prop="chain" width="288" align="center"/>
          <el-table-column label="Organism" width="188" align="left">
            <template #default="scope">
              <div v-if="searchType=='organism'" v-html="formatHightLight(scope.row.organism)">
              </div>
              <div v-else>
                {{ scope.row.organism }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Ligand Binding Sites" width="auto" min-width="500">
            <template #default="scope">
              <div v-if="searchType=='ligand'" v-html="formatHightLight(fmtBindSite(scope.row.sites))">
              </div>
              <div v-else>
                {{ fmtBindSite(scope.row.sites) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" width="288">
            <template #header>
              <!--              <el-button type="default" style="padding: 5px;" @click="downSeqText">download(selected)</el-button>-->
              <span>download binding sites</span>
            </template>
            <template #default="scope">
              <el-button type="text" @click="downSeqText1(scope.row)">download</el-button>
            </template>
          </el-table-column>

        </el-table>
        <br><br>
        <el-pagination
            style="margin-left: 13%;margin-top: 10px;"
            pager-count="15"
            background
            :current-page="currentPage"
            :page-size="size"
            :page-sizes="[15,30,60,120,240]"
            @size-change="handleSizeChange"
            :total="total"
            layout="total, prev,  pager,  next, sizes,  jumper"
            @current-change="handleCurrentChange"
        ></el-pagination>
        <br><br>
      </div>
    </el-main>
    <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
    <el-footer>
      <Footer></Footer>
    </el-footer>
  </el-container>
</template>

<script>
import Header from '@/views/Index/Header.vue'
import {getRequest} from "@/utils/api";
import {ElMessage} from "element-plus";
import Footer from "@/views/Index/Footer.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "BindSearch",
  components: {Footer, Header},
  data() {
    return {
      size: 15,
      currentPage: 1,
      dataList: [],
      total: null,
      isSearch: false,
      pid: '',
      organism: '',
      searchType: '',
      ligand: ''
    }
  },
  mounted() {
  },
  methods: {
    downloadTextFile(text, filename) {
      // 创建一个 Blob 对象，并指定 MIME 类型为 text/plain
      const blob = new Blob([text], {type: 'text/plain'});
      // 创建一个 URL 对象，指向 Blob 对象
      const url = URL.createObjectURL(blob);
      // 创建一个 <a> 元素，并设置 download 属性和 href 属性为 URL 对象
      const a = document.createElement('a');
      a.download = filename;
      a.href = url;
      // 将 <a> 元素添加到文档中，并模拟点击它
      document.body.appendChild(a);
      a.click();
      // 删除 <a> 元素和 URL 对象
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
    downSeqText1(row) {
      let text = '';
      text += row.pid + '\n' + row.chain + '\n' + row.sites;
      this.downloadTextFile(text, row.pid);
    },
    goToShow(pid) {
      window.location.href = '/?#/show?pid=' + pid;
    },
    formatOrg(cellValue) {
      let substring = this.organism;
      const redText = '<span style="color:black;background: yellow;">' + substring + '</span>';
      const highlightedText = cellValue.replace(new RegExp(substring, "gi"), redText);
      return highlightedText;
    },
    formatHightLight(cellValue) {
      let substring = '';
      switch (this.searchType) {
        case 'pid':
          substring = this.pid;
          break;
        case 'organism':
          substring = this.organism;
          break;
        case 'ligand':
          substring = this.ligand;
          break;
      }
      const regex = new RegExp(substring, "gi");
      const highlightedText = cellValue.replace(regex, function (match) {
        return '<span style="color:black;background: yellow;">' + match + '</span>';
      });
      return highlightedText;
    },
    fmtBindSite(sites) {


      return sites;


      // return null;
    },
    pidListSearch() {
      // console.log("111")
      getRequest('/bindsite/list?searchType=' + 'pid&&page=' + this.currentPage + '&&size=' + this.size + '&&keyword=' + this.pid).then(resp => {
        if (resp) {
          // console.log(resp)
          this.dataList = [];
          this.dataList = resp.data;
          this.total = resp.total;
          this.searchType = 'pid';
          this.isSearch = true;
          // console.log(this.dataList)
          // console.log(resp);
        }
      })
    },
    ligandListSearch() {
      // console.log('222')
      getRequest('/bindsite/list?searchType=' + 'ligand&&page=' + this.currentPage + '&&size=' + this.size + '&&keyword=' + this.ligand).then(resp => {
        if (resp) {
          this.dataList = [];
          this.dataList = resp.data;
          this.total = resp.total;
          this.searchType = 'ligand';
          this.isSearch = true;
          // console.log(resp);
        }
      })
    },
    orgListSearch() {
      getRequest('/bindsite/list?searchType=' + 'organism&&page=' + this.currentPage + '&&size=' + this.size + '&&keyword=' + this.organism).then(resp => {
        if (resp) {
          this.dataList = [];
          this.dataList = resp.data;
          this.total = resp.total;
          this.searchType = 'organism';
          this.isSearch = true;
          // console.log(resp);
        }
      })
    },
    selectReSearch() {
      switch (this.searchType) {
        case 'pid':
          this.pidListSearch();
          break;
        case 'organism':
          this.orgListSearch();
          break;
        case 'ligand':
          this.ligandListSearch();
          break;
      }
    },
    handleSizeChange(newSize) {
      this.size = newSize;
      this.selectReSearch();
    },
    handleCurrentChange(newPage) {
      this.currentPage = newPage;

      this.selectReSearch();
    },
  }
}
</script>

<style scoped>

/deep/ .el-table thead tr > th {
  color: #000764;
  background-color: #f3f3f3;
}

/* 更改表格每行背景颜色*/
/deep/ .el-table tbody tr > td {
  color: #2d4259;
  background-color: #f2f2f2;
}

/* 设置鼠标经过时背景颜色*/
/deep/ .el-table tbody tr:hover > td {
  background-color: #FFFFF0 !important;
}

.table-own thead {
  background-color: #f2f2f2;
}

.table-own th,
.table-own td {
  padding: 6px;
  text-align: center;
  /*border: 1px solid #ddd;*/
}

.table-own th.sn,
.table-own td.sn {
  text-align: center;
  /*width: 88px;*/
}

.table-own th.pdb-id,
.table-own td.pdb-id {
  text-align: center;
  /*width: 188px;*/
}

.table-own th.description,
.table-own td.description {
  text-align: center;
  width: auto;
}

.table-own th.pdb-file,
.table-own td.pdb-file {
  text-align: left;
  /*width: 158px;*/
}

.table-own tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table-own tbody tr:hover {
  background-color: #ddd;
}

.link-style {
  text-decoration: none;
  color: #007bff;
}

:deep(.el-pagination.is-background .el-pager li:not(.is-disabled).is-active) {
  background-color: rgb(127, 134, 217) !important;
}

input[type="text"] {
  width: 40%;
  height: 41px;
  padding: 5px 10px;
  font-size: 15px;
  border: none;
  border-radius: 1px;
  outline: none;
  box-shadow: 0 0 5px #ccc;
  transition: all 0.3s ease-in-out;
}

input[type="text"]:focus {
  /*width: 250px;*/
  box-shadow: 0 0 10px #a6a4a4;
}

.link-style {
  color: rgba(0, 0, 0, 0.93);
  text-decoration: none; /* 去掉下划线 */
  position: relative; /* 让子元素相对于父元素进行定位 */
}

.link-style::before {
  content: ""; /* 添加一个伪元素作为动画效果的基本元素 */
  position: absolute;
  bottom: -3px; /* 将伪元素定位在链接文本底部 */
  width: 0%;
  height: 2px;
  /*background-color: rgba(255, 0, 0, 0.91);*/
  background-color: red;
  transition: all 0.25s ease-in-out; /* 添加所有CSS属性过渡效果 */
}

.link-style:hover::before {
  width: 180%; /* 鼠标悬停时将伪元素宽度调整到100% */
  margin-left: -39%;
}


.uniqueProteinChains {
  width: auto;
}

.organism {
  width: auto;
}

table {
  border-collapse: collapse;
  /*box-shadow: 2px 2px 12px rgba(189, 170, 170, 0.84);*/
}

td {
  width: 233px;
  /*border: 3px solid #7e7e7e;*/
  /*padding: 5px;*/
}

.niam {
  /*justify-content: center;*/
  background-color: #f2f2f2;
  position: absolute;
  top: 6%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}


/* 输入框样式 */
.my-input {
  width: 666px;
  border: #496686 2px solid;
  border-radius: 5px;
  background-color: #f2f2f2;
  height: 42px;
  font-size: 18px;
  color: #333;
  /*box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);*/
  transition: all 0.3s ease;
}

.my-input:focus {
  /*width: 666px;*/
  outline: none;
  border-color: #4c5fa2;
  box-shadow: 0px 2px 10px rgba(126, 156, 255, 0.5);
}

/* 按钮样式 */
.my-button {
  margin-left: 15px;
  height: 46px;
  border: 1px #496686 solid;
  width: 72px;
  background-color: #496686;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.my-button:hover {
  background-color: #6772e5;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.my-button:focus {
  outline: none;
  box-shadow: 0px 2px 10px rgba(126, 156, 255, 0.5);
}


</style>
