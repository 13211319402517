<template>
  <div>
    <el-table table-layout="auto" :data="waitList" style="width: 100%;font-size: 14px;" stripe>
      <el-table-column type="expand" align="left" width="11">
        <template v-slot="props">
          <el-card style="width: 66%;margin-left: 8%;"  shadow="hover">
            <el-descriptions :column="1" border>
              <el-descriptions-item label="Release Date">
                {{ props.row.releaseDate }}
              </el-descriptions-item>
              <el-descriptions-item label="Deposition Author">
                {{ props.row.depositionAuthor }}
              </el-descriptions-item>
              <el-descriptions-item label="Total Structure Weight">
                {{ props.row.totalStructureWeight }}
              </el-descriptions-item>
              <el-descriptions-item label="Atom Count">
                {{ props.row.atomCount }}
              </el-descriptions-item>
              <el-descriptions-item label="Modelled Residue Count">
                {{ props.row.modelledResidueCount }}
              </el-descriptions-item>
              <el-descriptions-item label="Deposited Residue Count">
                {{ props.row.depositedResidueCount }}
              </el-descriptions-item>
              <el-descriptions-item label="Unique Protein Chains">
                {{ props.row.uniqueProteinChains }}
              </el-descriptions-item>
            </el-descriptions>
          </el-card>
        </template>
      </el-table-column>
      <el-table-column type="index" label="SN" align="center" width="88"/>
      <el-table-column prop="pid" label="PID" align="left" width="111"/>
      <el-table-column prop="description" label="DESCRIPTION" width="auto"/>
      <el-table-column align="left" label="PBD-FILE" width="121">
        <template v-slot="scope">
          <el-button type="default" style="background-color: #436e9d;color: white" @click="saveAsPDBFile(scope.row.pid,scope.row.pdbStr)">Download
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="ACTION" width="188">
        <template v-slot="scope">
          <el-button @click="openChangeDia(scope.row)">Re-upload</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-model="dialogVisible" width="888">
      <el-descriptions
          :column="1"
          border
          size="small"
      >
        <el-descriptions-item label="Pid">
          <el-input v-model="changedPdb.pid" placeholder="eg. 1i3u" class="my-input"></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="Description">
          <el-input class="my-input"
                    placeholder="eg. THREE-DIMENSIONAL STRUCTURE OF A LLAMA VHH DOMAIN COMPLEXED WITH THE DYE RR1"
                    v-model="changedPdb.description"></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="Release Date">
          <el-input class="my-input" placeholder="eg. 2001-08-08" v-model="changedPdb.releaseDate"></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="Deposition Author">
          <el-input class="my-input"
                    placeholder="eg. Spinelli, S., Tegoni, M., Frenken, L., van Vliet, C., Cambillau, C."
                    v-model="changedPdb.depositionAuthor"></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="Total Structure Weight">
          <el-input class="my-input" placeholder="eg. 14.55 kDa" v-model="changedPdb.totalStructureWeight"></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="Atom Count">
          <el-input class="my-input" placeholder="eg. 1186" v-model="changedPdb.atomCount"></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="Modelled Residue Count">
          <el-input class="my-input" placeholder="eg. 127" v-model="changedPdb.modelledResidueCount"></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="Deposited Residue Count">
          <el-input class="my-input" placeholder="eg. 127" v-model="changedPdb.depositedResidueCount"></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="Unique Protein Chains">
          <el-input class="my-input" placeholder="eg. 1" v-model="changedPdb.uniqueProteinChains"></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="Pdb File">
          <label>
            <input type="file" id="pdb_upload" name="pdb_upload" multiple accept=".pdb" @change="handleFileChange"
                   style="display: none">
            <span style=" display: inline-block;
                        padding: 2px 10px;
                        border: 1px solid rgba(0,0,0,0.61);
                        border-radius: 2px;
                        cursor: pointer;
                        background-color: rgba(227,227,227,0.78);font-family: Arial;color: black">Select Pdb File</span>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;selected:&nbsp;&nbsp;{{ this.filename }}</span>
          </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </el-descriptions-item>
        <el-descriptions-item label="Action">
          <el-button @click="uploadPdbMethod" type="success">Submit</el-button>
          <el-button @click="cleanAll" type="danger">Clean</el-button>
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <div style="margin-top: 2%;margin-left: 1%;">
      <el-pagination
          :current-page="currentPage"
          :page-sizes="total"
          :page-size="size"
          @size-change="handleSizeChange"
          layout="total, sizes,  prev,  pager,  next,  jumper"
          :total="total"
          @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import {getRequest, putRequest} from "@/utils/api";
import {ElMessage} from "element-plus";

export default {
  name: "waitList",
  data() {
    return {
      waitList: [],
      page: 1,
      currentPage: 1,
      total: '',
      size: 14,
      dialogVisible: false,
      changedPdb: {},
      filename: ''
    }
  },
  mounted() {
    this.getWaitList();
  },
  methods: {
    uploadPdbMethod() {
      delete this.changedPdb.id;
      delete this.changedPdb.success;
      delete this.changedPdb.uid;
      delete this.changedPdb.reason;
      if (Object.keys(this.changedPdb).length == 10) {
        putRequest('/upload', this.changedPdb).then(resp => {
          if (resp) {
            // console.log(resp);
            this.filename = null;
            this.changedPdb = {};
          }
        })
      } else {
        ElMessage.error('Please Enter All Values');
      }
    },
    cleanAll() {
      this.filename = '';
      this.changedPdb = {};
      ElMessage.success('ok!');
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.filename = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          this.changedPdb.pdbStr = reader.result;
        };
        reader.onerror = () => {
          console.error('file load error');
        };
        reader.readAsText(file);
      } else {
        this.filename = null;
        this.changedPdb.pdbStr = null;
      }
    },
    openChangeDia(data) {
      this.changedPdb = data;
      this.filename = this.changedPdb.pid + '.pdb';
      // console.log(this.changedPdb);
      this.dialogVisible = true;
    },
    getWaitList() {
      getRequest('/upload/list?page=' + this.currentPage + '&&size=' + this.size).then(resp => {
        if (resp) {
          this.waitList = resp.data;
          this.total = resp.total;
          if(this.total < this.size){
            this.size = this.total;
          }
        }
      })
    },
    saveAsPDBFile(pid, str) {
      const blob = new Blob([str], {type: 'chemical/x-pdb'});
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = pid + '.pdb';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  handleCurrentChange(newPage) {
    this.currentPage = newPage;
    this.getWaitList();
  },
  handleSizeChange(newSize) {
    this.size = newSize;
    this.getWaitList();
  },
}
}
</script>

<style scoped>

</style>
